import React, { useEffect, useState } from 'react';
import { _fetch } from '../config/api';
import { base_url } from '../config/config';

const Genelogy = () => {
  const [data, setData] = useState([]);
  const [activeNodes, setActiveNodes] = useState([]);
  const [loadingNodes, setLoadingNodes] = useState([]);


  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const request = await _fetch(`${base_url}api/genelogy`, 'GET', {}, {});
        if (request.status === true) {
          setData(request?.response.directs || []);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  
  const toggleNode = async (user_id, all_directs) => {
  
    if (all_directs === 0) return;

    const isActive = activeNodes.includes(user_id);

    
    if (isActive) {
      setActiveNodes((prevState) =>
        prevState.filter((nodeUser_id) => nodeUser_id !== user_id)
      );
      return;
    }

   
    setLoadingNodes((prevState) => [...prevState, user_id]);

    try {
    
      const response = await _fetch(`${base_url}api/genelogy-users/${user_id}`, 'GET', {}, {});

      
      setData((prevData) => {
        const updateDataWithChildren = (nodes) => {
          return nodes.map((node) => {
            if (node.user_id === user_id) {
              
              return { ...node, children: response.response.directs || [] };
            } else if (node.children) {
             
              return { ...node, children: updateDataWithChildren(node.children) };
            }
            return node;
          });
        };

        return updateDataWithChildren(prevData);
      });

    
      setActiveNodes((prevState) => [...prevState, user_id]);
    } catch (error) {
      console.error('Error fetching children data:', error);
    } finally {
      
      setLoadingNodes((prevState) => prevState.filter((nodeId) => nodeId !== user_id));
    }
  };

 
  const renderTree = (node) => {
    return (
      <li key={node.user_id}>
        <div className={`caret ${activeNodes.includes(node.user_id) ? 'caret-down' : ''}`}
          onClick={() => toggleNode(node.user_id, node.all_directs)}
        >
          <div className='alin_c'>
          <i class="fa-solid fa-arrow-turn-up fa-fade hello"></i>
          </div>
          <div className="card-gen">
            {/* <img src={node.profile_picture || 'https://placehold.co/50x50'} alt="pic" /> */}
            <img src="https://user.infinitemlmsoftware.com/images/user-profile.png" alt="pic" />
            <div className="info-gen">
              <h5>{node.name}</h5>
              <p>{node.all_directs} Members</p>
            </div>
            <div className="badge-gen">{node.user_id}</div>
          </div>
          {loadingNodes.includes(node.user_id) && <p>Loading...</p>}
        </div>

        {/* Render children if node is active */}
        {node.children && activeNodes.includes(node.user_id) && (
          <ul className={`nested active`}>
            {node.children.map((child) => renderTree(child))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <ul id="myUL">
      {data.map((parent) => renderTree(parent))}
    </ul>
  );
};

export default Genelogy;


