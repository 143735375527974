



// export const base_url = `https://decentralized-plan.jstechservices.us/stake`

// export const base_url = `https://decentralized-plan.jstechservices.us/stake/`
// export const base_url = `https://cvtoken.us/backend/`
// export const base_url = `https://panel.cvtoken.us/`
// http://65.0.184.195/~panel/backend/
// export const base_url = `http://65.0.184.195/~panel/backend/`
// export const base_url = `https://cvtoken.us/backend/`
// export const base_url = `https://cvtoken.online/backend/`
export const base_url = `http://35.154.191.60/~panel/backend/`
// http://65.0.184.195/~panel/backend/      
// https://panel.cvtoken.us/api/login
// http://35.154.191.60/~panel/backend/api/login