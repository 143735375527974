import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { base_url } from '../config/config';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
const StarclubBonus = () => {
    const [leader, setLeader] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [leftside, setLeftside] = useState('');
    const [rightside, setRight_side] = useState('');
    const [cvtdirectbusiness, setCvtdirectbusiness] = useState('');
    const [usdtdirectbusiness, setUsdtdirectbusiness] = useState('');
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState('')
    const navigate = useNavigate(); // Initialize useNavigate for navigation


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/starclub_rewards?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});
            if (request.status === true) {
                setLeader(request.response.rewards);
                setLeftside(request.response.left_side);
                setRight_side(request.response.right_side);
                setCvtdirectbusiness(request.response.cvt_direct_business);
                setUsdtdirectbusiness(request.response.usdt_direct_business);
                setLoading(false);
            } else {
                toast.dismiss()
                toast.error(request.message)
                setLoading(false);
            }
        } catch (error) {
            console.log("Error fetching data:", error);
            setLoading(false);
        }
    };


    const handleActionClick = (item) => {
        if (item.action === "View") {
            navigate(`/view/${item.id}`);
        } else if (item.action === "Claim") {
            claimBonus(item.key);
        } else if (item.action === "Not Eligible") {
            toast.dismiss()

            toast.error("This bonus is not claimed yet.")
        }
    };

    const claimBonus = async (id) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }
        const requestData = {
            id
        }
        try {

            let response = await fetch(`${base_url}api/claim_star_reward`, {
                method: 'POST',
               
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("data", data);
            // Change this endpoint as needed
            if (data.status === true) {
                // alert("Bonus claimed successfully!");
                toast.success(data.message)
                fetchData(); // Refresh the data after claiming
            } else {
                // alert("Failed to claim bonus.");
                toast.error(data.message)
            }
        } catch (error) {
            console.log("Error claiming bonus:", error);
        }
    };



    return (
        <>
            <div className="onew p-1">
                <div className="heading">
                    <h4 className='bep_2'>Star Club Bonus</h4>
                </div>
                <div class="card">
                    <div class="card-body cash">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <div className='ovbut '>
                                    <p className='ovi'> Left side:- {leftside}  </p>
                                </div>
                                <div className='ovbut'>
                                    <p className='ovi'> Right side:- {rightside}</p>

                                </div>

                            </div>
                            <div className="">

                                <div className='ovbut'  >
                                    <p className='ovi'>Direct Business:-  {cvtdirectbusiness}</p>

                                </div>

                                <div className='ovbut'  >
                                    <p className='ovi'>Direct Business:-  {usdtdirectbusiness}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">

                        {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                            <table className="table">
                                <thead className='table-heading'>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Matching</th>
                                        <th scope="col">Bonus</th>
                                        <th scope="col">Directs</th>
                                        <th scope="col">Daily</th>
                                        <th scope="col">Monthly</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody className='tb_colo'>
                                    {leader && leader.length > 0 ? (
                                        leader.map((item, index) => (
                                            <tr key={index}>
                                                <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                                <td>{item.matching}</td>
                                                <td>{item.bonus}</td>
                                                <td>{item.direct}</td>
                                                <td>{item.daily}</td>
                                                <td>{item.monthly}</td>
                                                {/* <td>
                                                    <button onClick={() => handleActionClick(item)} className="btn btn-primary">
                                                        {item.action}
                                                    </button>
                                                </td> */}
                                                <td>
                                                    <button
                                                        onClick={() => handleActionClick(item)}
                                                        className={`btn ${item.action === 'Claim' ? 'btn btn_g' :
                                                            item.action === 'View' ? 'btn_g vie_btn' :
                                                                'btn-primary btn_01s'
                                                            }`}
                                                    >
                                                        {item.action}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td scope='row' colSpan={6} style={{ textAlign: 'center' }}>No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StarclubBonus;

