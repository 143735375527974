import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import popup from "../assets/icons/popup.png"

const style = {
    textAlign: 'center',
    borderRadius: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 270,
    backgroundColor: 'rgba(38, 38, 38, 1)',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'rgba(255, 255, 255, 1)',
    padding: 4,
    fontFamily: "Montserrat",
};


export default function ModalPop({ open, handleClose }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className='popup-img'>
                            <img src={popup} alt='suceess' />
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="suc">
                            Success!
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description">
                        <div className="auth">
                            Login Authentication successful!
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

