import React, { useEffect, useState, useRef } from "react";
import logoPic from "../assets/images/cvt logo web.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { base_url } from "../config/config";
import icon_1 from "../assets/icons/category-2.png";
import icon_2 from "../assets/icons/game.png";
import icon_3 from "../assets/icons/key-square.png";
import GeeTest from "react-geetest-v4";

const SignUp = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sponserIdFromQuery = queryParams.get("sponser_id");
  const [phone, setPhone] = useState({
    countryCode: "91",
    phone: "",
    countryName: "India",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [sponser_id, setSponser_id] = useState(sponserIdFromQuery || "");
  const [names, setNames] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(null);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const initGeetest = {
    captchaId: "ef041711f043110d988e3d8bee7bccd2", // Use your actual captcha ID
    product: "float",
    language: "eng",
    riskType: "slide",
  };

  // Effect to handle signup after captcha success
  useEffect(() => {
    if (captchaResult && pendingSubmission) {
      submitSignup();
      setPendingSubmission(false);
    }
  }, [captchaResult, pendingSubmission]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCaptchaSuccess = (result) => {
    setCaptchaResult(result);
  };

  const handleCaptchaError = (error) => {
    console.error("Captcha error:", error);
    setCaptchaResult(null);
    setIsSubmitting(false);
    setPendingSubmission(false);
  };

  const handleCaptchaClose = () => {
    if (!captchaResult) {
      setIsSubmitting(false);
      setPendingSubmission(false);
    }
  };

  const handleUserIdChange = (e) => {
    const userId = e.target.value.trim();
    setSponser_id(userId);
  };

  const validateInputs = () => {
    if (!sponser_id || !email || !name) {
      toast.dismiss();
      toast.error("Please enter all required details");
      return false;
    }

    const phoneNumber = phone.phone.replace(`+${phone.countryCode}`, "");
    if (!validatePhone(phoneNumber, phone.countryCode)) {
      toast.dismiss();
      toast.error("Invalid Phone Number");
      return false;
    }

    if (!isChecked) {
      toast.dismiss();
      toast.error("Please accept the terms & conditions");
      return false;
    }

    return true;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Prevent multiple submissions
    if (isSubmitting) return;

    // Validate inputs
    if (!validateInputs()) return;

    setIsSubmitting(true);

    // If captcha isn't completed, trigger it
    if (!captchaResult) {
      setPendingSubmission(true);
      const geetestButton = document.querySelector(".geetest_btn_click");
      if (geetestButton) {
        geetestButton.click();
      }
      return;
    }

    // If captcha is already completed, submit directly
    submitSignup();
  };

  const submitSignup = async () => {
    const phoneNumber = phone.phone.replace(`+${phone.countryCode}`, "");

    try {
      const requestData = {
        name,
        phone: phoneNumber,
        country: phone?.countryName,
        sponser_id,
        email,
        country_code: `+${phone?.countryCode}`,
        captcha: {
          lot_number: captchaResult.lot_number,
          captcha_output: captchaResult.captcha_output,
          sign_token: captchaResult.pass_token,
          gen_time: captchaResult.gen_time,
        },
      };

      const response = await fetch(`${base_url}api/generate_register_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (data.status === true) {
        toast.success("OTP sent successfully");
        navigate("/otp", {
          state: {
            name,
            phone: phoneNumber,
            country: phone?.countryName,
            sponser_id,
            email,
            country_code: `+${phone?.countryCode}`,
          },
        });
      } else {
        toast.dismiss();
        toast.error(data.error || "Registration failed");
        resetCaptcha();
      }
    } catch (error) {
      console.error("Error with registration:", error);
      toast.error("Registration failed. Please try again.");
      resetCaptcha();
    } finally {
      setIsSubmitting(false);
      setPendingSubmission(false);
    }
  };

  const resetCaptcha = () => {
    setCaptchaResult(null);
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };

  const validatePhone = (phoneNumber) => {
    const pattern = phonePatterns[phone.countryCode];
    return pattern
      ? pattern.test(phoneNumber)
      : /^\+?[1-9]\d{1,14}$/.test(phoneNumber);
  };

  const phonePatterns = {
    US: /^\+1\d{10}$/,
  };

  const handlebalance = async () => {
    if (!sponser_id.trim()) return;

    try {
      const response = await fetch(`${base_url}api/check_sponser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sponser_id: sponser_id.trim() }),
      });

      const data = await response.json();
      if (data.status === true) {
        setNames(data.name);
        setMessage("");
      } else {
        setNames("");
        setMessage(data.message);
      }
    } catch (error) {
      console.error("Error fetching sponsor data:", error);
      setMessage("Error checking sponsor ID");
    }
  };

  useEffect(() => {
    if (sponser_id) {
      handlebalance();
    }
  }, [sponser_id]);

  return (
    <div className="page">
      <div className="container">
        <div className="row p-lg-3 py-5 justify-content-between g-3 mt-3">
          <div className="col-lg-5 col-md-4 d-none d-md-block">
            <div className="first_account">
              <div className="logo">
                <img src={logoPic} alt="cv_token" />
              </div>
              <div className="details">
                <h5>Get Started with Us🌟</h5>
                <p>Welcome to CV Token.Login to your account now.</p>
              </div>
              <div className="icons mt-5">
                <img src={icon_1} alt="icon_1" />
              </div>
              <div className="text">
                <h6>Decentralized Blockchain Asset</h6>
                <p>
                  CV Token (CVT) is a decentralized digital asset on the Binance
                  Smart Chain,offering secure and transparent transactions.
                </p>
              </div>
              <div className="icons mt-3">
                <img src={icon_2} alt="icon_2" />
              </div>
              <div className="text">
                <h6>Metaverse Commerce</h6>
                <p>
                  Use CV Tokens to buy and sell virtual products and services in
                  the Metaverse,enhancing your virtual reality experience.
                </p>
              </div>
              <div className="icons mt-3">
                <img src={icon_3} alt="icon_3" />
              </div>
              <div className="text">
                <h6>Market Exploration & Security</h6>
                <p>
                  The platform provides real-time market data,diverse trading
                  options,and prioritizes top-tier security for users.
                </p>
              </div>
            </div>
            <div className="policy-content-signup d-flex justify-content-space-between gap-5">
              <span>Privacy Policy</span>
              <span>Terms & Conditions</span>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12">
            <form className="signup-form mt-3">
              <div className="mb-3 signup">
                <div className="signup-sponsor">
                  {/* <label htmlFor="exampleFormControlInput1" className="form-label">Sponsor ID</label>
                                      
                                        <input type="text" className="input_style form-control" placeholder="Enter User ID" value={sponser_id} onChange={(e) => setSponser_id(e.target.value)} /> */}

                  <label htmlFor="user_id" className="form-label">
                    Sponsor ID
                  </label>
                  <input
                    type="text"
                    className="input_style form-control"
                    autoComplete="off"
                    placeholder="Enter User ID"
                    value={sponser_id}
                    onChange={handleUserIdChange}
                  />
                  {/* {names && (
                <p className="mt-2">{names}</p> 
            )} */}
                  {(names && <p className="mt-2">{names}</p>) ||
                    (message && message !== null && (
                      <p className="mt-2">{message}</p>
                    ))}
                </div>
                <div className="signup-Name mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="input_style form-control"
                    placeholder="Enter Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="signup-email mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="input_style form-control"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="signup-PhoneNumber mt-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="number-style"
                  >
                    Phone Number
                  </label>

                  <PhoneInput
                    country={"in"}
                    value={phone.phone}
                    onChange={(value, country) => {
                      setPhone({
                        countryCode: country.dialCode,
                        phone: value,
                        countryName: country.name,
                      });
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      // autoFocus: true
                      Autocomplete: "off",
                    }}
                    inputClass="input_style"
                    containerClass="input_style_phone"
                    inputStyle={{
                      border: "1px solid ",
                      borderRadius: "10px",
                      padding: "10px 10px 10px 59px",
                    }}
                    buttonStyle={{
                      borderRadius: "10px 0 0 20px",
                      border: "1px solid ",
                    }}
                  />
                </div>
                <div id="captcha" className="captcha2">
                  <div className="captcha-section">
                    <GeeTest
                      ref={captchaRef}
                      {...initGeetest}
                      onSuccess={handleCaptchaSuccess}
                      onError={handleCaptchaError}
                      onClose={handleCaptchaClose}
                    />
                  </div>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input input-checkbox"
                    type="checkbox"
                    id="gridCheck"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="gridCheck">
                    I agree to the terms & conditions{" "}
                  </label>
                </div>
                <div className="mb-3 button">
                  <button
                    type="submit"
                    className="login-button"
                    onClick={handleSignup}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Create Account" : "Create Account"}
                  </button>
                </div>
              </div>
            </form>

            <p className="already text-center">
              Already have an account?
              <NavLink to="/login" className="link">
                <span className="g_c">Login</span>
              </NavLink>
            </p>
            <div className="last-div"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
