import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { base_url } from "../config/config";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton"; // Add the skeleton loader
import "react-loading-skeleton/dist/skeleton.css";
const Ebalance = () => {
  const [leader, setLeader] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [per_page] = useState(10);
  const [totalPages, setTotalPages] = useState(0);  
  const [loading, setLoading] = useState(true);
  const [usdtprice, setUsdtprice] = useState("");
  const [usdtdirectbusiness, setUsdtdirectbusiness] = useState("");
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    fetchData();
  }, [currentPage, startDate, endDate]);

  console.log("usdtdirectbusiness", usdtdirectbusiness);

  const token = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      const requestData = {
        startdate: startDate || undefined,
        enddate: endDate || undefined,
      };
      setLoading(true);

      const response = await fetch(
        `${base_url}api/team-business-search-report?page=${currentPage}&per_page=${per_page}`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const request = await response.json();
      if (request.status === true) {
        console.log("level-income", request);
        setLeader(request.data);
        console.log(request);
        setCurrentPage(parseInt(request.pagination.current_page, 10));
        setTotalPages(parseInt(request.pagination.total_pages, 10));
        setUsdtdirectbusiness(request.usdt_amount);
        setUsdtprice(request.usdt_price);
        setLoading(false);
      } else {
        toast.dismiss();
        toast.error(request.message);
        setLoading(false);
      }
      // setCurrentPage(parseInt(request.pagination.current_page, 10));
      // setTotalPages(parseInt(request.pagination.total_pages, 10));
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    }
  };
  

  const handlePageClick = (e, page) => {
    e.preventDefault();
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePreviousClick = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const clearFilter = ()=>{
    setStartDate('');
    setEndDate('');
  }

  const handleNextClick = (e) => {
    e.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxPageNumbersToShow = 5;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }
    return pages;
  };

  const formattedBalance = usdtdirectbusiness
    ? parseFloat(usdtdirectbusiness).toFixed(4)
    : "0.0000";
  const formattedBalances = usdtprice
    ? parseFloat(usdtprice).toFixed(4)
    : "0.0000";
  return (
    <>
      <div className="onew p-1">
        <div className="heading d-flex flex-xl-row flex-wrap gap-1">
          <h4 className="bep_2 mt-1 me-md-0 me-3">Team Business Report</h4>
          <p className="button-background ebalBtn">
            {usdtdirectbusiness}/${usdtprice}
          </p>
          <div className="py-2 py-md-0 dateFilter EbalFilter d-flex">
            <input
              className="rounded mx-md-2 me-2 align-self-center"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              className="rounded align-self-center"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <i className="EbalReferesh align-self-center btn text-white bi bi-arrow-clockwise fs-4" onClick={clearFilter}></i>
        </div>

        <div className="rounded">
          <div className="table-responsive mt-3">
            {loading ? (
              <Skeleton
                count={10}
                height={50}
                baseColor="#202020"
                highlightColor="#444"
              />
            ) : (
              <table class="table">
                <thead className="table-heading">
                  <tr>
                    <th scope="col">Sr.No.</th>
                    <th scope="col">User Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Level</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>

                <tbody className="tb_colo">
                  {leader && leader.length > 0 ? (
                    leader.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">
                            {(currentPage - 1) * per_page + index + 1}
                          </td>
                          <td scope="row">{item.user_id}</td>
                          <td scope="row">{item.name}</td>
                          <td scope="row">{item.level}</td>
                          <td scope="row">{item.token_price}</td>
                          <td scope="row">{item.amount}</td>
                          <td scope="row">{item.date}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        scope="row"
                        colSpan={7}
                        style={{ textAlign: "center" }}
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            <div className="pagination-custom">
              <nav aria-label="Pagination">
                <ul className="pagination justify-content-center">
                  {totalPages > 1 && (
                    <li
                      className={`page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={handlePreviousClick}
                      >
                        <span aria-hidden="true">&larr;</span> Previous
                      </a>
                    </li>
                  )}

                  <div className="page-numbers">
                    {renderPageNumbers().map((page, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          page === currentPage ? "active" : ""
                        }`}
                      >
                        {page === "..." ? (
                          <span className="page-link">...</span>
                        ) : (
                          <a
                            className="page-link"
                            href="#"
                            onClick={(e) => handlePageClick(e, page)}
                          >
                            {page}
                          </a>
                        )}
                      </li>
                    ))}
                  </div>

                  {totalPages > 1 && (
                    <li
                      className={`page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={handleNextClick}
                      >
                        Next <span aria-hidden="true">&rarr;</span>
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ebalance;
