import React, { useState, useEffect } from 'react'
import { base_url } from '../config/config'
import toast from 'react-hot-toast'
import _fetch from '../config/api'
const StakeToken = () => {

    const [amount, setAmount] = useState();
    const [wallet, setWallet] = useState();

    const handleStake = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        // console.log("Using token:", token);

        try {
            const requestData = {
                amount,
                package_id: "400"
            };

            let response = await fetch(`${base_url}api/stake_tokens`, {
                method: 'POST',
              
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            if (data.status === true) {
                toast.dismiss();
                toast.success(data.message)
                fetchData()
                setAmount('')

            } else {
                toast.dismiss();
                toast.error(data.message)
            }
            // console.log("Response data:", data);

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const fetchData = async () => {
        try {





            const request = await _fetch(`${base_url}api/dashboard`, 'GET', {}, {});
            // console.log("request", request);
            if (request.status === true) {
                setWallet(request?.topup_fund)


            } else {
                toast.error(request.message)

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    console.log('wallet', wallet);

    useEffect(() => {
        fetchData();
    }, [])

    


    const handlePercentageClick = (percentage) => {
        if (wallet) {
            const calculatedAmount = (wallet * (percentage / 100)).toFixed(4);
            setAmount(calculatedAmount);
        }
    };
    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4 className='bep_2'>Staking Plan</h4>
                </div>
                <form className="sta-form">
                    <div className="mb-3 bg_2">
                        <div className="heading_inner p-1 mb-3">
                            <h4>Staking Plan</h4>
                        </div>
                        <div className="login-data mt-2">
                            {/* <div className='headlight-data m-3'><span className='headlight-data-span'>We are offering you Tokens in the Presale Round Price we wish you a bright future</span></div> */}
                            <div className='headlight-data text-center m-3'><span className='headlight-data-span '>Minimum Topup  5 CVT</span></div>
                        </div>
                        <div className='wallet mt-5'>
                            <span>Wallet Balance</span>
                        </div>
                        <div className="balance mt-2 mb-4">
                            <span>{wallet}</span>
                        </div>
                        <div className="login-amount mt-3">
                            <input type="amount" className="input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="percentage-buttons mt-3">
                            <button type="button" onClick={() => handlePercentageClick(25)}>25%</button>
                            <button type="button" onClick={() => handlePercentageClick(50)}>50%</button>
                            <button type="button" onClick={() => handlePercentageClick(75)}>75%</button>
                            <button type="button" onClick={() => handlePercentageClick(100)}>100%</button>
                        </div>
                        <div className="mb-3 button mt-5">
                            <button type="submit" className="login-button" onClick={handleStake}>Stake</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default StakeToken