import React, { useState, useRef, useEffect } from "react";
import logoPic from "../assets/images/cvt logo web.png";
import { NavLink, useNavigate } from "react-router-dom";
import { base_url } from "../config/config";
import toast from "react-hot-toast";
import icon_1 from "../assets/icons/category-2.png";
import icon_2 from "../assets/icons/game.png";
import icon_3 from "../assets/icons/key-square.png";
import GeeTest from "react-geetest-v4";

const Login = () => {
  const [user_id, setUser_id] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const initGeetest = {
    captchaId: "ef041711f043110d988e3d8bee7bccd2",
    product: "float",
    language: "eng",
    riskType: "slide",
  };

  // Effect to handle login after captcha success
  useEffect(() => {
    if (captchaResult && pendingSubmission) {
      submitLogin();
      setPendingSubmission(false);
    }
  }, [captchaResult, pendingSubmission]);

  const handleCaptchaSuccess = (result) => {
    setCaptchaResult(result);
  };

  const handleCaptchaError = (error) => {
    console.error("Captcha error:", error);
    setCaptchaResult(null);
    setIsSubmitting(false);
    setPendingSubmission(false);
  };

  const handleCaptchaClose = () => {
    if (!captchaResult) {
      setIsSubmitting(false);
      setPendingSubmission(false);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    
    // Prevent multiple submissions
    if (isSubmitting) return;
    
    // Basic validation for user_id and password
    if (!user_id || !password) {
      toast.dismiss();
      toast.error("Please enter the details");
      return;
    }

    setIsSubmitting(true);

    // If captcha isn't completed, trigger it
    if (!captchaResult) {
      setPendingSubmission(true);
      const geetestButton = document.querySelector(".geetest_btn_click");
      if (geetestButton) {
        geetestButton.click();
      }
      return;
    }

    // If captcha is already completed, submit directly
    submitLogin();
  };

  const submitLogin = async () => {
    try {
      const requestData = {
        user_id: user_id,
        password: password,
        captcha: {
          lot_number: captchaResult.lot_number,
          captcha_output: captchaResult.captcha_output,
          sign_token: captchaResult.pass_token,
          gen_time: captchaResult.gen_time,
        },
      };

      const response = await fetch(`${base_url}api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();

      if (data.status === true) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user_id", data.user_id);
        setUser_id("");
        setPassword("");
        setCaptchaResult(null);
        navigate("/");
      } else {
        toast.dismiss();
        toast.error(data.message);
        resetCaptcha();
      }
    } catch (error) {
      console.error("Error with login:", error);
      toast.error("Login failed. Please try again.");
      resetCaptcha();
    } finally {
      setIsSubmitting(false);
      setPendingSubmission(false);
    }
  };

  const resetCaptcha = () => {
    setCaptchaResult(null);
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };

  // Rest of the JSX remains the same
  return (
    <div className="page">
      <div className="container">
        <div className="row p-lg-4 py-5 justify-content-between g-2 mt-3">
          <div className="col-lg-5 col-md-4 d-none d-md-block">
            <div className="first_account">
              <div className="logo">
                <img src={logoPic} alt="cv_token" />
              </div>
              <div className="details">
                <h5>Login to your account🌟</h5>
                <p>Welcome to CV Token. Login to your account now.</p>
              </div>
              <div className="icons mt-5">
                <img src={icon_1} alt="icon_1" />
              </div>
              <div className="text">
                <h6>Decentralized Blockchain Asset</h6>
                <p>
                  CV Token (CVT) is a decentralized digital asset on the
                  Binance Smart Chain, offering secure and transparent
                  transactions.
                </p>
              </div>
              <div className="icons mt-3">
                <img src={icon_2} alt="icon_1" />
              </div>
              <div className="text">
                <h6>Metaverse Commerce</h6>
                <p>
                  Use CV Tokens to buy and sell virtual products and services
                  in the Metaverse, enhancing your virtual reality experience.
                </p>
              </div>
              <div className="icons mt-3">
                <img src={icon_3} alt="icon_1" />
              </div>
              <div className="text">
                <h6>Market Exploration & Security</h6>
                <p>
                  The platform provides real-time market data, diverse trading
                  options, and prioritizes top-tier security for users.
                </p>
              </div>
            </div>
            <div className="policy-content d-flex justify-content-space-between gap-5">
              <span>Privacy Policy</span>
              <span>Terms & Conditions</span>
            </div>
          </div>

          <div className="col-lg-6 col-md-8 col-sm-12">
            <form className="login-form" onSubmit={handleLogin}>
              <div className="bg">
                <div className="login-email">
                  <label htmlFor="user_id" className="form-label">
                    User ID
                  </label>
                  <input
                    type="text"
                    id="user_id"
                    className="input_style form-control"
                    autoComplete="off"
                    placeholder="Enter User ID"
                    value={user_id}
                    onChange={(e) => setUser_id(e.target.value)}
                  />
                </div>

                <div className="login-password mt-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <div className="password-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="input_style form-control"
                      autoComplete="off"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <i
                      className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  </div>
                </div>

                <div id="captcha">
                  <div className="captcha-section">
                    <GeeTest
                      ref={captchaRef}
                      {...initGeetest}
                      onSuccess={handleCaptchaSuccess}
                      onError={handleCaptchaError}
                      onClose={handleCaptchaClose}
                    />
                  </div>
                </div>
                <div className="form-check">
                  <div className="d-flex justify-content-between gap-5">
                    <div>
                      <input
                        className="form-check-input input-checkbox"
                        type="checkbox"
                        id="rememberMe"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rememberMe"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-3 button">
                  <button 
                    id="loginCaptcha" 
                    type="submit" 
                    className="login-button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Login" : "Login"}
                  </button>
                </div>

                <div className="forgot-pass">
                  <NavLink to="/forgot" className="forgot-link">
                    <span>Forgot Password?</span>
                  </NavLink>
                </div>
              </div>
            </form>

            <div className="now mt-5 d-flex justify-content-center">
              <p className="m-0">
                Don't have an account?{" "}
                <NavLink to="/signup" className="link">
                  <span>Create Now</span>
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;