// import logoPic from '../assets/images/cvt logo web.png'
// import React, { useState } from 'react'
// import { NavLink, useNavigate } from 'react-router-dom'
// import { base_url } from '../config/config'

// const Otp = () => {



//     return (
//         <>
//             <div className="page">
//                 <div className="first_account py-3">
//                     <div className="logo px-5">
//                         <img src={logoPic} alt='cv_token' />
//                     </div>
//                 </div>
//                 {/* <div className="otp"></div> */}
//                 <div className="row justify-content-center align-items-center mt-5">
//                     <div className="col-lg-5 col-md-9 col-sm-10 m-5">
//                         <form className="otp-form">
//                             <div className="mb-3 otp-ju">
//                                 <div className="heading mt-4">
//                                     <h4 className='bep_2'>Enter OTP</h4>
//                                 </div>
//                                 <div>
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                     <input type="text" className="otp-input" maxLength={1} />
//                                 </div>

//                                 <div className="mt-5 button">
//                                     <button type="submit" className="submit-button">Submit</button>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Otp


// import React, { useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom'; // useLocation to get passed state
// import { base_url } from '../config/config';
// import { toast } from 'react-toastify';
// import logoPic from '../assets/images/cvt logo web.png'
// const Otp = () => {
//     const [emails, setEmails] = useState('');
//     const [sponser_ids, setSponser_ids] = useState('');
//     const [otp, setOtp] = useState(['', '', '', '', '', '']);
//     const location = useLocation(); // Get the data passed from the signup page
//     const navigate = useNavigate(); 

//     // Destructure the data passed from signup page
//     const { name, phone, country, sponser_id, email, country_code } = location.state || {};

//     const handleSubmitOtp = async (e) => {
//         e.preventDefault();
//         const enteredOtp = otp.join('');
//         if (enteredOtp.length !== 6) {
//             toast.error('Please enter a valid 6-digit OTP');
//             return;
//         }

//         try {
//             const requestData = {
//                 name,
//                 phone,
//                 country,
//                 sponser_id,
//                 email,
//                 country_code,
//                 otp: enteredOtp,
//             };

//             const response = await fetch(`${base_url}api/register`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestData),
//             });

//             const data = await response.json();
//             console.log("Registration API Response:", data);

//             if (data.success === true) {
//                 toast.dismiss()
//                 toast.success(data.message);
//                 navigate('/login'); // Redirect to login page on success
//             } else {
//                 toast.dismiss()
//                 toast.error(data.error);
//             }
//         } catch (error) {
//             console.error('Error during registration:', error);
//         }
//     };

//     const handleOtpChange = (index, value) => {
//         if (isNaN(value)) return; // Ensure only numbers are entered
//         const updatedOtp = [...otp];
//         updatedOtp[index] = value;
//         setOtp(updatedOtp);
//     };





//     return (
//         <>
//          <div className="page">
//                  <div className="first_account py-3">
//                      <div className="logo px-5">
//                          <img src={logoPic} alt='cv_token' />
//                      </div>
//                  </div>
//                  {/* <div className="otp"></div> */}
//                  <div className="row justify-content-center align-items-center mt-5">
//                      <div className="col-lg-5 col-md-9 col-sm-10 m-5">
//                          <form className="otp-form text-center" onSubmit={handleSubmitOtp}>
//                              <div className="mb-3 otp-ju">
//                                  <div className="heading mt-4">
//                                      <h4 className='bep_2 '>Enter OTP</h4>
//                                  </div>
//                                  <div className='mt-4'>
//                             {otp.map((digit, index) => (
//                                 <input
//                                     key={index}
//                                     type="text"
//                                     className="otp-input"
//                                     maxLength={1}
//                                     value={digit}
//                                     onChange={(e) => handleOtpChange(index, e.target.value)}
//                                 />
//                             ))}
//                         </div>

//                                  <div className="mt-5 button d-flex justify-content-between mt-2">
//                                      <button type="submit" className="submit-button">Submit</button>
//                                      <button type="submit" className="submit-button">Resend</button>
//                                  </div>
//                              </div>
//                          </form>
//                      </div>
//                  </div>
//              </div>
//         </>
      
//     );
// };

// export default Otp;

// import React, { useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { base_url } from '../config/config';
// import { toast } from 'react-toastify';
// import logoPic from '../assets/images/cvt logo web.png';

// const Otp = () => {
//     const [otp, setOtp] = useState(['', '', '', '', '', '']);
//     const location = useLocation();
//     const navigate = useNavigate();

//     // Destructure the data passed from the signup page
//     const { name, phone, country, sponser_id, email, country_code } = location.state || {};

//     // Handle OTP submission
//     const handleSubmitOtp = async (e) => {
//         e.preventDefault();
//         const enteredOtp = otp.join('');
//         if (enteredOtp.length !== 6) {
//             toast.error('Please enter a valid 6-digit OTP');
//             return;
//         }

//         try {
//             const requestData = {
//                 name,
//                 phone,
//                 country,
//                 sponser_id,
//                 email,
//                 country_code,
//                 otp: enteredOtp,
//             };

//             const response = await fetch(`${base_url}api/register`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestData),
//             });

//             const data = await response.json();
//             console.log("Registration API Response:", data);

//             if (data.success === true) {
//                 toast.dismiss();
//                 toast.success(data.message);
//                 navigate('/login'); // Redirect to login page on success
//             } else {
//                 toast.dismiss();
//                 toast.error(data.error);
//             }
//         } catch (error) {
//             console.error('Error during registration:', error);
//         }
//     };

//     // Handle OTP resend
//     const handleResendOtp = async () => {
//         try {
//             const requestData = {
//                 email,
//                 sponser_id,
//             };

//             const response = await fetch(`${base_url}api/generate_register_otp`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestData),
//             });

//             const data = await response.json();
//             console.log("Resend OTP API Response:", data);

//             if (data.status === true) {
//                 toast.success('OTP resent successfully');
//             } else {
//                 toast.error(data.error);
//             }
//         } catch (error) {
//             console.error('Error during OTP resend:', error);
//             toast.error('Failed to resend OTP');
//         }
//     };

//     // Handle OTP input change
//     const handleOtpChange = (index, value) => {
//         if (isNaN(value)) return; // Ensure only numbers are entered
//         const updatedOtp = [...otp];
//         updatedOtp[index] = value;
//         setOtp(updatedOtp);
//     };
//     const preventPaste = (e) => {
//         e.preventDefault();
//     };

//     return (
//         <>
//             <div className="page">
//                 <div className="first_account py-3">
//                     <div className="logo px-5">
//                         <img src={logoPic} alt='cv_token' />
//                     </div>
//                 </div>
//                 <div className="row justify-content-center align-items-center mt-5">
//                     <div className="col-lg-5 col-md-9 col-sm-10 m-5">
//                         <form className="otp-form text-center" onSubmit={handleSubmitOtp}>
//                             <div className="mb-3 otp-ju">
//                                 <div className="heading mt-4">
//                                     <h4 className='bep_2 '>Enter OTP</h4>
//                                 </div>
//                                 <div className='mt-4'>
//                                     {otp.map((digit, index) => (
//                                         <input
//                                             key={index}
//                                             type="text"
//                                             className="otp-input"
//                                             maxLength={1}
//                                             value={digit}
//                                             onInput={(e) => handleOtpChange(index, e.target.value)}
//                                             onPaste={preventPaste} 
//                                         />
//                                     ))}
//                                 </div>
//                                 <div className=" ios">
//                                     <button type="submit" className="login-button">Submit</button>
//                                     <button type="button" className="login-button iosb" onClick={handleResendOtp}>Resend</button>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Otp;


// import React, { useState, useRef } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { base_url } from '../config/config';
// import { toast } from 'react-toastify';
// import logoPic from '../assets/images/cvt logo web.png';

// const Otp = () => {
//     const [otp, setOtp] = useState(['', '', '', '', '', '']);
//     const location = useLocation();
//     const navigate = useNavigate();

   
//     const { name, phone, country, sponser_id, email, country_code } = location.state || {};

   
//     const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

   
//     const handleSubmitOtp = async (e) => {
//         e.preventDefault();
//         const enteredOtp = otp.join('');
//         if (enteredOtp.length !== 6) {
//             toast.error('Please enter a valid 6-digit OTP');
//             return;
//         }

//         try {
//             const requestData = {
//                 name,
//                 phone,
//                 country,
//                 sponser_id,
//                 email,
//                 country_code,
//                 otp: enteredOtp,
//             };

//             const response = await fetch(`${base_url}api/register`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestData),
//             });

//             const data = await response.json();
//             console.log("Registration API Response:", data);

//             if (data.success === true) {
//                 toast.dismiss();
//                 toast.success(data.message);
//                 navigate('/login'); 
//             } else {
//                 toast.dismiss();
//                 toast.error(data.error);
//             }
//         } catch (error) {
//             console.error('Error during registration:', error);
//         }
//     };

    
//     const handleResendOtp = async () => {
//         try {
//             const requestData = {
//                 email,
//                 sponser_id,
//             };

//             const response = await fetch(`${base_url}api/generate_register_otp`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(requestData),
//             });

//             const data = await response.json();
//             console.log("Resend OTP API Response:", data);

//             if (data.status === true) {
//                 toast.success('OTP resent successfully');
//             } else {
//                 toast.error(data.error);
//             }
//         } catch (error) {
//             console.error('Error during OTP resend:', error);
//             toast.error('Failed to resend OTP');
//         }
//     };

   
//     const focusNext = (e, index) => {
//         const value = e.target.value;
//         if (isNaN(value)) return; 
//         const updatedOtp = [...otp];
//         updatedOtp[index] = value;
//         setOtp(updatedOtp);

        
//         if (value.length === e.target.maxLength && index < inputRefs.length - 1) {
//             inputRefs[index + 1].current.focus();
//         }
//     };

//     return (
//         <div className="page">
//             <div className="first_account py-3">
//                 <div className="logo px-5">
//                     <img src={logoPic} alt="cv_token" />
//                 </div>
//             </div>
//             <div className="row justify-content-center align-items-center mt-5">
//                 <div className="col-lg-5 col-md-9 col-sm-10 m-5">
//                     <form className="otp-form text-center" onSubmit={handleSubmitOtp}>
//                         <div className="mb-3 otp-ju">
//                             <div className="heading mt-4">
//                                 <h4 className="bep_2">Enter OTP</h4>
//                             </div>
//                             <div className="mt-4">
//                                 {otp.map((digit, index) => (
//                                     <input
//                                         key={index}
//                                         ref={inputRefs[index]}
//                                         type="text"
//                                         className="otp-input"
//                                         maxLength={1}
//                                         value={digit}
//                                         onInput={(e) => focusNext(e, index)}
//                                         style={styles.input}
//                                     />
//                                 ))}
//                             </div>
//                             <div className="ios">
//                                 <button type="submit" className="login-button">Submit</button>
//                                 <button type="button" className="login-button iosb" onClick={handleResendOtp}>Resend</button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // Some basic styling for the inputs
// const styles = {
//     input: {
//         width: '40px',
//         height: '40px',
//         fontSize: '18px',
//         textAlign: 'center',
//         marginRight: '10px',
//     },
// };

// export default Otp;


import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url } from '../config/config';
import toast from 'react-hot-toast'
import logoPic from '../assets/images/cvt logo web.png';

const Otp = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure the data passed from the signup page
    const { name, phone, country, sponser_id, email, country_code } = location.state || {};

    // Create refs for each OTP input field
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    // Handle OTP submission
    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('');
        if (enteredOtp.length !== 6) {
            toast.error('Please enter a valid 6-digit OTP');
            return;
        }

        try {
            const requestData = {
                name,
                phone,
                country,
                sponser_id,
                email,
                country_code,
                otp: enteredOtp,
            };

            const response = await fetch(`${base_url}api/register`, {
                method: 'POST',
               
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("Registration API Response:", data);

            if (data.success === true) {
                toast.dismiss();
                // toast.success(data.message);
                // navigate('/login'); // Redirect to login page on success
                navigate('/information', { state: { userData: data } });
            } else {
                toast.dismiss();
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    // Handle OTP resend
    const handleResendOtp = async () => {
        try {
            const requestData = {
                email,
                sponser_id,
            };

            const response = await fetch(`${base_url}api/generate_register_otp`, {
                method: 'POST',
               
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("Resend OTP API Response:", data);

            if (data.status === true) {
                toast.success('OTP resent successfully');
            } else {
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error during OTP resend:', error);
            toast.error('Failed to resend OTP');
        }
    };

    // Function to move focus to the next input
    const focusNext = (index) => {
        if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    // Handle input change
    const handleOtpChange = (index, value) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);

        if (value.length === 1) {
            focusNext(index);
        }
    };

    // Handle pasting
    const handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('text');
        const otpArray = pastedValue.split('').slice(0, 6); // Take only the first 6 characters
        const updatedOtp = [...otpArray, ...otp.slice(otpArray.length)]; // Fill the OTP array
        setOtp(updatedOtp);

        // Focus on the next empty input
        for (let i = 0; i < updatedOtp.length; i++) {
            if (updatedOtp[i] === '') {
                inputRefs[i].current.focus();
                break;
            }
        }
    };

    return (
        <div className="page">
            <div className="first_account py-3">
                <div className="logo px-5">
                    <img src={logoPic} alt="cv_token" />
                </div>
            </div>
            <div className="row justify-content-center align-items-center mt-5">
                <div className="col-lg-5 col-md-9 col-sm-10 m-5">
                    <form className="otp-form text-center" onSubmit={handleSubmitOtp}>
                        <div className="mb-3 otp-ju">
                            <div className="heading mt-4">
                                <h4 className="bep_2">Enter OTP</h4>
                            </div>
                            <div className="mt-4">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        className="otp-input"
                                        maxLength={1}
                                        value={digit}
                                        onInput={(e) => handleOtpChange(index, e.target.value)}
                                        onPaste={handlePaste} // Handle paste
                                        style={styles.input}
                                    />
                                ))}
                            </div>
                            <div className="btn_g1">
                                <button type="submit" className="login-button1 mt-4 px-3">Submit</button>
                                <button type="button" className="resend01 " onClick={handleResendOtp}>Resend</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};


const styles = {
    input: {
        width: '40px',
        height: '40px',
        fontSize: '18px',
        textAlign: 'center',
        marginRight: '10px',
    },
};

export default Otp;
