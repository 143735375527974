import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
import toast from 'react-hot-toast'
import { NavLink } from 'react-router-dom';
const DepositHistory = () => {

    const [depositHistory, setDepositHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData();
    }, [currentPage])


    const fetchData = async () => {
        try {
            setLoading(true);

            const request = await _fetch(`${base_url}api/deposithistory/usdt?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});
            console.log("staking_income", request)
            if (request.status === true) {
                setDepositHistory(request.token_deposit_report)
                setTotalPages(parseInt(request.pagination.total_pages, 10));
                setTotalRecords(parseInt(request.pagination.total_records, 10));
                setLoading(false);
            } else {
                toast.dismiss()
                toast.error(request.message)
                setLoading(false);
            }


        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }


    // const fetchData = async () => {
    //     try {
    //         const request = await _fetch(`${base_url}api/deposithistory/newcvt`, 'GET', {}, {})
    //         console.log("staking_income", request)
    //         setDepositHistory(request.token_deposit_report)

    //     } catch (error) {
    //         console.log("Error fetching data:", error);
    //     }
    // }

    // console.log("token", depositHistory);


    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };
    return (
        <>
            <div className="onew p-1">
                <div className="heading">
                    <h4 className='bep_2'>USDT Deposit History</h4>
                </div>
                <div className="market-data">
                   
                    <div className="table-responsive mt-3">
                        {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                            <table class="table m-0">
                                <thead className='table-heading'>

                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Hash</th>
                                        <th scope="col">From</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody className='tb_colo'>
                                {depositHistory && depositHistory.length > 0 ? (
                                    depositHistory.map((item, index) => (
                                        <tr key={index}>
                                            <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                            {/* <td scope='row'>{item.Hash.slice(0, 8)}</td> */}
                                            <td scope='row'>
                                                {/* <NavLink className='navlink' to={`https://bscscan.com/tx/${item.Hash}`}>
                                                {item.Hash?.length > 20
                                                    ? `${item.Hash?.slice(0, 20)}...`
                                                    : item.Hash}
                                            </NavLink> */}
                                                {/* <NavLink
                                                    className='navlink'
                                                    to={`https://bscscan.com/tx/${item.Hash}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(`https://bscscan.com/tx/${item.Hash}`, "_blank");
                                                    }}
                                                >
                                                    {item.Hash?.length > 20
                                                        ? `${item.Hash?.slice(0, 20)}...`
                                                        : item.Hash}
                                                </NavLink> */}

                                                <a
                                                    className='navlink'
                                                    href={`https://bscscan.com/tx/${item.Hash}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.Hash?.length > 20
                                                        ? `${item.Hash?.slice(0, 20)}...`
                                                        : item.Hash}
                                                </a>


                                            </td>
                                            <td scope='row'>{item.From.slice(0, 8)}</td>
                                            <td scope='row'>{item.Amount}</td>
                                            <td scope='row'>{item.Date}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td scope='row'  colSpan={5} style={{ textAlign: 'center' }}>No data found</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        )}
                        <div className="pagination-custom">
                            <nav aria-label="Pagination">
                                <ul className="pagination justify-content-center">

                                    {totalPages > 1 && (
                                        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                <span aria-hidden="true">&larr;</span> Previous
                                            </a>
                                        </li>
                                    )}


                                    <div className="page-numbers">
                                        {renderPageNumbers().map((page, index) => (
                                            <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                {page === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                        {page}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </div>


                                    {totalPages > 1 && (
                                        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handleNextClick}>
                                                Next <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default DepositHistory