// import React, { useEffect, useState } from 'react'
// import _fetch from '../config/api'
// import { base_url } from '../config/config'
// import qr_code from "../assets/icons/qr-code.png"
// import copy_icon from "../assets/icons/copy_icon.png"
// import { useNavigate } from 'react-router-dom';
// import QRCode from 'react-qr-code'
// const DepositBep = () => {

//     const [walletAddress, setWalletAddress] = useState();
//     const navigate = useNavigate();

//     const handleUsdtClick = () => {
//         navigate('/deposit_history');
//     };
//     const handleCvtClick = () => {
//         navigate('/token_deposit');
//     };

//     useEffect(() => {
//         fetchProfile();
//     }, []);

//     const fetchProfile = async () => {
//         try {
//             const request = await _fetch(`${base_url}api/depositfund`, 'GET', {}, {});
//             // console.log('request------>', request);

//             setWalletAddress(request.wallet_address)

//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     }


//     return (
//         <>
//             <div className="col-lg-6 col-md-10 col-sm-12">
//                 <div className="heading p-1 mb-3 mt-1">
//                     <h4 className='bep_2'>Deposit BEP 20</h4>
//                 </div>
//                 <form className="des-form">
//                     <div className="mb-3 bg_3">
//                         <div className="heading_inner p-1 mb-3 mt-4">
//                             <h4>Scan QR code to Deposit Coins</h4>
//                         </div>
//                         <div className="login-data d-flex justify-content-center mt-2">
//                             <div className='headlight-data_2 m-1'><span className='headlight-data-span'>Deposit BEP20 USDT Only</span></div>
//                         </div>
//                         <div className='qr_code mt-4 mb-5'>
//                             {walletAddress && (
//                                 <QRCode value={walletAddress} size={158} />
//                             )}
//                         </div>

//                         <div className="login-amount mt-3">
//                             <input type="text" className="input_style form-control" autoComplete='off' placeholder="0x788b87263bA4020" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
//                             <div className='copypaste_icon'>
//                                 <img src={copy_icon} alt='copy_icon' />
//                             </div>
//                         </div>

//                         <div className="d-flex justify-content-between mt-2">
//                             <button type="button" className=" button-usdt" onClick={handleUsdtClick}>USDT Deposit Report</button>
//                             <button type="button" className=" button-cvt" onClick={handleCvtClick}>CVT Deposit Report</button>
//                         </div>

//                         <div className="mb-3 button mt-4">
//                             <button type="submit" className="login-button">Confirm Deposit</button>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         </>
//     )
// }

// export default DepositBep


import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { base_url } from '../config/config';
import copy_icon from "../assets/icons/copy_icon.png";
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import toast from 'react-hot-toast'


const DepositBep = () => {
    const [copySuccess, setCopySuccess] = useState(false);
    const [walletAddress, setWalletAddress] = useState();
    const navigate = useNavigate();

    const handleUsdtClick = () => {
        navigate('/deposit_history');
    };
    const handleCvtClick = () => {
        navigate('/token_deposit');
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const request = await _fetch(`${base_url}api/depositfund`, 'GET', {}, {});
            if (request.status === true) {
                setWalletAddress(request?.message?.wallet_address);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handleCopyClick = () => {
    //     if (walletAddress) {
    //         navigator.clipboard.writeText(walletAddress)
    //         toast.dismiss()
    //         toast.success("Copied successfully")
    //     }
    // };
    const handleCopyClick = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(walletAddress)
                .then(() => {
                    setCopySuccess(true);
                    toast.dismiss();
                    toast.success("Copied successfully");
                    setTimeout(() => setCopySuccess(false), 2000);
                })
                .catch(err => {
                    toast.dismiss()
                    toast.error("Failed to copy");
                    console.error("Could not copy text: ", err);
                });
        } else {

            const textArea = document.createElement("textarea");
            textArea.value = walletAddress;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                toast.dismiss()
                toast.success("Copied successfully ");
            } catch (err) {
                toast.dismiss()
                toast.error("Fallback copy failed");
            }
            document.body.removeChild(textArea);
        }}

    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4 className='bep_2'>Deposit BEP 20</h4>
                </div>
                {/* hello */}
                <form className="des-form">
                    <div className="mb-3 bg_3 back_03">
                        <div className="heading_inner p-1 mb-3 ">
                            <h4>Scan QR code to Deposit Coins</h4>
                        </div>
                        <div className="login-data d-flex justify-content-center mt-2">
                            <div className='headlight-data_2 m-1'>
                                <span className='headlight-data-span'>Deposit BEP20 USDT Only</span>
                            </div>
                        </div>
                        <div className='qr_code mt-4 mb-5'>
                            {walletAddress && (
                                <QRCode value={walletAddress} size={158} />
                            )}
                        </div>

                        <div className="login-amount mt-3">
                            <input
                                type="text"
                                className="input_style form-control"
                                autoComplete='off'
                                placeholder="0x788b87263bA4020"
                                value={walletAddress}
                                onChange={(e) => setWalletAddress(e.target.value)}
                            />
                            <div
                                className='copypaste_icon'
                                onClick={handleCopyClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <img src={copy_icon} alt='copy_icon' />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mt-2">
                            <button type="button" className="button-usdt" onClick={handleUsdtClick}>USDT Deposit History</button>
                            <button type="button" className="button-cvt" onClick={handleCvtClick}>CVT Deposit History</button>
                        </div>

                        {/* <div className="mb-3 button mt-4">
                            <button type="submit" className="login-button">Confirm Deposit</button>
                        </div> */}
                    </div>
                </form>
            </div>
            {/* <ToastContainer /> */}
        </>
    );
};

export default DepositBep;
