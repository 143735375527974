import React, { useState } from 'react'
import { base_url } from '../config/config';
import { NavLink } from 'react-router-dom'
import toast from 'react-hot-toast'

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [reconfirmPassword, setReconfirmPassword] = useState('');
    // const [showPassword, setShowPassword] = useState(false);



    const handlePassword = async (e) => {
        e.preventDefault();
        if (!currentPassword || !confirmPassword || !reconfirmPassword) {
            toast.dismiss();
            toast.error('Please enter the details');
            return;
        }

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        try {
            const datatransfer = {
                cpassword: currentPassword,
                npassword: confirmPassword,
                vpassword: reconfirmPassword,
            };

            let response = await fetch(`${base_url}api/change-password`, {
                method: 'POST',
               
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(datatransfer),
            });

            const data = await response.json();
            // console.log("Response data:", data);
            if (data.status === true) {
                toast.success(data.message)
                setCurrentPassword('')
                setConfirmPassword('')
                setReconfirmPassword('')
            } else {
                toast.error(data.message)
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            <div className="row gap-5">
                <div className="col-lg-3 col-md-4 col-sm-3">
                    <ul className='nav nav-pills  flex-column mt-sm-0'>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/account' className='nav-link account-setting text-white fs-5' aria-current='page'>
                                <span className='ms-3'>Manage Profile</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/change_password' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className='ms-3'>Change Password</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
                            <NavLink to='/manage_stake' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className='ms-3'>Manage Stack Address</span>
                            </NavLink>
                        </li>
                    </ul>

                </div>
                <div className="col-lg-6 col-md-9 col-sm-8">
                    <form className="login-form">
                        <div className="mb-3 bg-password">
                            <div className="login-password mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label label-input">Current Password</label>
                                <input type='password' className="textarea input_style form-control" autoComplete='off' placeholder="Enter current password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}  />
                                {/* <i
                                                className={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}
                                                onClick={() => setShowPassword(!showPassword)}
                                            ></i> */}
                            </div>
                            <div className="login-password mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label label-input">New Password</label>
                                <input type='Password' className="textarea input_style form-control" autoComplete='off' placeholder="Enter new Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                            <div className="login-password mt-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label label-input">Confirm Password</label>
                                <input type='Password' className="textarea input_style form-control" autoComplete='off' placeholder="Enter confirm password" value={reconfirmPassword} onChange={(e) => setReconfirmPassword(e.target.value)} />
                                <div className="mb-3 button mt-5">
                                    <button type="submit" className="login-button" onClick={handlePassword}>Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ChangePassword

