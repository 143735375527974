// import React from 'react'

// const Createtickettable = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default Createtickettable

import React, { useEffect, useState } from 'react'
import { base_url } from '../config/config';
import _fetch from '../config/api';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
const Createtickettable = () => {

    const [ticketHistory, setTicketHistory] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchTicket();
    }, [currentPage])


    const fetchTicket = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/compose-mail-data?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});
            // console.log('request------>', request);
            if (request.status === true) {
                setTicketHistory(request.data)
                setTotalPages(parseInt(request.pagination.total_pages, 10));
                setTotalRecords(parseInt(request.pagination.total_records, 10));
                setLoading(false);
            } else {
                toast.dismiss()
                toast.error(request.message)
                setLoading(false);

            }

        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }

    }
    // console.log("hege", ticketHistory);

    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };


    return (
        <>
            <div className="onew p-3 mt-5">
                <div className="heading">
                    <h5>Ticket List</h5>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                        {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                            <table class="table">
                                <thead className='table-heading'>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Remarks</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody className='tb_colo'>
                                {ticketHistory && ticketHistory.length > 0 ? (
                                    ticketHistory.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                                <td scope='row'>{item.title}</td>
                                                <td scope='row'>{item.message}</td>
                                                <td scope='row'>{item.status}</td>
                                                <td scope='row'>{item.remark}</td>
                                                <td scope='row'>{item.date}</td>
                                            </tr>
                                        )
                                    })) : (
                                        <tr>
                                            <td scope='row'  colSpan={6} style={{ textAlign: 'center' }}>No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                        <div className="pagination-custom">
                            <nav aria-label="Pagination">
                                <ul className="pagination justify-content-center">

                                    {totalPages > 1 && (
                                        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                <span aria-hidden="true">&larr;</span> Previous
                                            </a>
                                        </li>
                                    )}


                                    <div className="page-numbers">
                                        {renderPageNumbers().map((page, index) => (
                                            <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                {page === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                        {page}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </div>


                                    {totalPages > 1 && (
                                        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handleNextClick}>
                                                Next <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Createtickettable