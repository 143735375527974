import React, { useEffect, useState } from "react";
import { base_url } from "../config/config";
import toast from "react-hot-toast";
import _fetch from "../config/api";
import { useNavigate } from "react-router-dom";

const UsdtWithdrawals = () => {
  // 17-9-24 "CV Token Api lga hai aaj (done by- Madhukar) "

  const [amount, setAmount] = useState("");
  const [eth_address, setEth_address] = useState("");
  const [balance, setBalance] = useState("");
  const [balAmount, setBalAmount] = useState("");
  const navigate = useNavigate();

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const request = await fetch(`${base_url}api/dashboard`, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await request.json();
      // console.log("request", request);
      if (data.status) {
        setBalAmount(data?.usdt_balance);
        setEth_address(data?.user_data?.rbc_address);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // console.log("eth", eth_address);

  //   const formattedBalance = balAmount.wallet_address
  //     ? parseFloat(balAmount.wallet_address).toFixed(4)
  //     : "0.0000";
  //   console.log(formattedBalance);

  // console.log(balAmount);

  //   const formattedBalance = parseFloat(balAmount.wallet_address).toFixed(4)
  //   console.log(formattedBalance);
  //   console.log(formattedBalance);

  //   const handleWallet = async (e) => {
  //     e.preventDefault();
  //     const token = localStorage.getItem("token");
  //     if (!amount || !eth_address) {
  //       toast.dismiss();
  //       toast.error("Please enter the details");
  //       // alert('Please enter the details')
  //       return;
  //     }
  //     if (!token) {
  //       console.error("Token not found");
  //       return;
  //     }

  //     try {
  //       // const token = localStorage.getItem('token');
  //       const request = await _fetch(
  //         `${base_url}api/generate_user_otp`,
  //         "GET",
  //         {},
  //         {
  //           Authorization: token,
  //         }
  //       );

  //       if (request.status === true) {
  //         toast.success(request.message);
  //         navigate("/withdrawotp", {
  //           state: {
  //             amount,
  //             eth_address,
  //           },
  //         });
  //       } else {
  //         toast.error(request.message);
  //       }
  //     } catch (error) {
  //       console.error("Error sending OTP request:", error);
  //     }
  //   };

  const handleTicket = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!amount || !eth_address) {
      toast.dismiss();
      toast.error("Please enter the details");
      // alert('Please enter the details')
      return;
    }
    if (!token) {
      console.error("Token not found");
      return;
    }

    // console.log("token", token);

    try {
      // const token = localStorage.getItem('token');
      const request = await _fetch(
        `${base_url}api/generate_user_otp`,
        "GET",
        {},
        {
          Authorization: token,
        }
      );

      if (request.status === true) {
        toast.success(request.message);
        navigate("/usdtotp", {
          state: {
            amount,
            eth_address,
          },
        });
      } else {
        toast.error(request.message);
      }
    } catch (error) {
      console.error("Error sending OTP request:", error);
    }
  };
  const handlePercentageClick = (percentage) => {
    if (balAmount) {
      const calculatedAmount = (balAmount * (percentage / 100)).toFixed(4);
      setAmount(calculatedAmount);
    }
  };
  return (
    <>
      <div className="col-lg-6 col-md-10 col-sm-12">
        <div className="heading p-1 mb-3 mt-1 d-flex">
          <h4 className="bep_2">Available USDT Balance</h4>
          <p className="button-background">{balAmount}</p>
        </div>
        <form className="login-form">
          <div className="mb-3 transfer">
            <div className="login-email mt-4">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Amount
              </label>
              <input
                type="text"
                className="input_style form-control"
                autoComplete="off"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="login-password mt-4">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Wallet Address
              </label>
              <input
                type="text"
                className="textarea input_style form-control"
                autoComplete="off"
                placeholder="Enter Wallet Address"
                value={eth_address}
                onChange={(e) => setEth_address(e.target.value)}
              />
              <div className="percentage-buttons mt-3">
                <button type="button" onClick={() => handlePercentageClick(25)}>
                  25%
                </button>
                <button type="button" onClick={() => handlePercentageClick(50)}>
                  50%
                </button>
                <button type="button" onClick={() => handlePercentageClick(75)}>
                  75%
                </button>
                <button
                  type="button"
                  onClick={() => handlePercentageClick(100)}
                >
                  100%
                </button>
              </div>
              <div className="mb-3 button mt-5">
                <button
                  type="submit"
                  className="login-button"
                  onClick={handleTicket}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UsdtWithdrawals;
