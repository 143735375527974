import React, { useState, useEffect } from 'react'
import { base_url } from '../config/config';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';


import _fetch from '../config/api';

const CvtWithdrawals = () => {

    const [amount, setAmount] = useState("");
    const [eth_address, setEth_address] = useState("");
    const [business, setBusiness] = useState();
    const [rbcaddress, setRbcaddress] = useState();
    // console.log("eth",eth_address);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


    //     const handleTicket = async (e) => {
    //         e.preventDefault();

    //         const token = localStorage.getItem("token");
    //         if (!token) {
    //             console.error("Token not found");
    //             return; 
    //         }

    //         if (!amount || !eth_address) {
    //             toast.dismiss();
    //             toast.error('Please enter the details');
    //             // alert('Please enter the details')
    //             return;
    //         }


    //         try {
    //             const requestData = {
    //                 amount,
    //                 eth_address,
    //             };

    //             let response = await fetch(`${base_url}api/cvtamount-withdraw`, {
    //                 method: 'POST',
    //                 mode: 'cors',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     "Authorization": token,
    //                 },
    //                 body: JSON.stringify(requestData),
    //             })

    //             const data = await response.json();
    //             console.log("data", data);



    //             if (data.status === true) {
    // toast.dismiss();
    // toast.success(data.message)
    // setAmount("");
    //             setEth_address("");
    //             } else {
    //                 toast.dismiss();
    //                 toast.error(data.message)
    //             }


    //         } catch (error) {
    //             console.error('Error with email login:', error);
    //         }
    //     }


    const handleWallet = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        if (!amount || !eth_address) {
            toast.dismiss();
            toast.error('Please enter the details');
            // alert('Please enter the details')
            return;
        }
        if (!token) {
            console.error("Token not found");
            return;
        }

        try {
            // const token = localStorage.getItem('token');
            const request = await _fetch(`${base_url}api/generate_user_otp`, 'GET', {}, {
                Authorization: token
            });


            if (request.status === true) {
                toast.success(request.message);
                navigate('/withdrawotp', {
                    state: {
                        amount,
                        eth_address

                    }
                });

            } else {
                toast.error(request.message);
            }
        } catch (error) {
            console.error('Error sending OTP request:', error);
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/dashboard`, 'GET', {}, {});
            // console.log("request", request);
            if (request.status === true) {
                setBusiness(request?.wallet_balance)
                setEth_address(request?.user_data.rbc_address || "");
                setLoading(false);
            } else {
                toast.error(request.message)
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [])
    const formattedBalance = business ? parseFloat(business).toFixed(4) : "0.0000";
    const handlePercentageClick = (percentage) => {
        if (business) {
            const calculatedAmount = (business * (percentage / 100)).toFixed(4);
            setAmount(calculatedAmount);
        }
    };
    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1 d-flex">
                    {/* {/ <div className="heading d-flex"> /}
                    {/ <h4 className='bep_2 mt-1  ' >Withdrawal History </h4> /}

                    {/ </div> /} */}
                    <h4 className='bep_2 mt-1 '  >Available Balance</h4>
                    <p className='button-background  '>{formattedBalance}</p>
                </div>
                <form className="login-form">
                    <div className="mb-3 transfer">
                        <div className="login-email mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Amount</label>
                            <input type="number" className="input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                        <div className="login-password mt-4">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Wallet Address</label>

                            {/* {/ <input type='text' className="textarea input_style form-control" autoComplete='off'  value={eth_address} onChange={(e) => setEth_address(e.target.value)} /> /} */}
                            <input
                                type="text"
                                className="textarea input_style form-control"
                                autoComplete="off"
                                value={eth_address}
                                readOnly
                                onChange={(e) => setEth_address(e.target.value)} // This will allow updates
                            />
                             <div className="percentage-buttons mt-3">
                                <button type="button" onClick={() => handlePercentageClick(25)}>25%</button>
                                <button type="button" onClick={() => handlePercentageClick(50)}>50%</button>
                                <button type="button" onClick={() => handlePercentageClick(75)}>75%</button>
                                <button type="button" onClick={() => handlePercentageClick(100)}>100%</button>
                            </div>
                            
                            <div className="mb-3 button mt-5">
                                <button type="submit" className="login-button" onClick={handleWallet}>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CvtWithdrawals