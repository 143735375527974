import React, { useEffect, useState, useRef } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import _fetch from "../config/api";
import { base_url } from "../config/config";
import vector from "../assets/icons/Vector.png";
import vector_1 from "../assets/icons/Vector (1).png";
// import copy from "../assets/icons/copy_paste.png"
import facebook from "../assets/icons/facebook.png";
import vecto from "../assets/icons/Vector (2).png";
import instagram from "../assets/icons/instagram.png";
import youtube from "../assets/icons/youtube.png";
import frame_1 from "../assets/icons/Frame (1).png";
import copyed from "../assets/icons/copyed.png";
// import ReactSpeedometer from 'react-d3-speedometer';
// import frame_2 from "../assets/icons/Frame (2).png"
// import frame_3 from "../assets/icons/Frame (3).png"
// import frame_4 from "../assets/icons/Frame (4).png"
// import frame_5 from "../assets/icons/Frame (5).png"
// import frame_6 from "../assets/icons/Frame (6).png"
import one from "../assets/icons/one.png";
import oneg from "../assets/icons/oneg.png";
import two from "../assets/icons/two.png";
import twog from "../assets/icons/twog.png";
import there from "../assets/icons/three.png";
import threeg from "../assets/icons/threeg.png";
import four from "../assets/icons/four.png";
import fourg from "../assets/icons/fourg.png";
import five from "../assets/icons/five.png";
import fiveg from "../assets/icons/fiveg.png";
import six from "../assets/icons/six.png";
import sixg from "../assets/icons/sixg.png";
import seven from "../assets/icons/seven.png";
import seveng from "../assets/icons/seveng.png";
import eight from "../assets/icons/eight.png";
import eightg from "../assets/icons/eightg.png";

import car1 from "../assets/icons/car1.png";
import car2 from "../assets/icons/car2.png";
import car3 from "../assets/icons/car3.png";
import ModalPop from "../Authentication/ModalPop";
import cap from "../assets/icons/cap.png";
import active from "../assets/icons/active .png";
import settings from "../assets/icons/settings.png";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton"; // Add the skeleton loader
import "react-loading-skeleton/dist/skeleton.css";
import CVTLOGO from "../assets/icons/CVTLOGO.png";
import link from "../assets/icons/link.png";
import fac1 from "../assets/icons/facebook(2).png";
import fac2 from "../assets/icons/youtube(2).png";
import fac3 from "../assets/icons/instagram(1).png";
import fac4 from "../assets/icons/twitter(2).png";
import fac5 from "../assets/icons/whatsapp.png";
import Car1 from "../assets/icons/money (4).png";
import Cvtcoin from "../assets/icons/new_tok_i.png";
import ReactSpeedometer from "react-d3-speedometer";
const Dashboard = () => {
  const [token, setToken] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [founders, setFounders] = useState([]);
  const [userId, setUserId] = useState();
  const [sponserId, setSponserId] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [partners, setPartners] = useState();
  const [founder, setFounder] = useState();
  const [thailand, setThailand] = useState([]);
  const [monsoon, setMonsoon] = useState([]);
  const [leadershipwinners, setLeadershipwinners] = useState([]);
  const [tokenPrice, setTokenPrice] = useState([]);
  const [capping, setCapping] = useState([]);
  const [referral, setReferral] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [business, setBusiness] = useState();
  const [todaybusiness, setTodaybusiness] = useState();
  const [todayincome, setTodayincome] = useState();
  const [per_page] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [hub, setHub] = useState();
  const [starclubcount, setSarclubcount] = useState("");
  const [status, setStatus] = useState(null);
  const [overallbusiness, setOverallbusiness] = useState("");
  const [rbcaddress, rbcAddress] = useState("");
  // console.log("hub", hub);
  const [datesbusiness, setDatesbusiness] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState(true);
  const [incomelimitpercent, setIncomelimitpercent] = useState(0);
  const marqueeRef = useRef(null);
  const marqueeRefs = useRef(null);
  const marqueeRefes = useRef(null);
  const [leadershiprewards, setLeadershiprewards] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false);
  const [cvt, setCvt] = useState("");
  const [usdt, setUsdt] = useState("");

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
    setCvt("");
    setUsdt("");
  };

  useEffect(() => {
    if (!isFlipped) {
      setUsdt(cvt ? (cvt * tokenPrice).toFixed(2) : "");
    } else {
      setCvt(usdt ? (usdt / tokenPrice).toFixed(2) : "");
    }
  }, [cvt, usdt, isFlipped, tokenPrice]);

  const handleCvtChange = (e) => {
    setCvt(e.target.value);
    if (!isFlipped) {
      setUsdt(e.target.value * tokenPrice);
    }
  };

  const handleUsdtChange = (e) => {
    setUsdt(e.target.value);
    if (isFlipped) {
      setCvt(e.target.value / tokenPrice);
    }
  };

  useEffect(() => {
    const hasSeenModal = localStorage.getItem("hasSeenModal"); // Check if modal has already been shown

    if (!hasSeenModal) {
      setShowModal(true);
      localStorage.setItem("hasSeenModal", "true");
    }
    // setShowModal(true);
    fetchProfile();
    fetchData();
    fetchfounder();
    fetchReward();
  }, [currentPage]);

  const handleClose = () => setShowModal(false);

  const fetchProfile = async () => {
    try {
      const request = await _fetch(`${base_url}api/profile`, "GET", {}, {});
      // console.log('request------>', );

      setProfileName(request?.user_data?.name);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchfounder = async () => {
    try {
      const request = await _fetch(
        `${base_url}api/check-eps-founder`,
        "GET",
        {},
        {}
      );
      setFounders(request?.message || []); // Assuming 'message' contains ["Founder", "EPS_Partner"]
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchReward = async () => {
    try {
      setLoadings(true);
      const request = await _fetch(
        `${base_url}api/company_rewards_list`,
        "GET",
        {},
        {}
      );
      if (request.status === true) {
        toast.dismiss();
        // toast.success(request.message);
        setHub(request?.data?.rewards || []);
        setOverallbusiness(request?.data?.overall_business);
        setDatesbusiness(request?.data?.dates_business);
        setLoadings(false);
      } else {
        toast.dismiss();
        toast.error(request.message);
        setLoadings(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const request = await _fetch(
        `${base_url}api/dashboard?page=${currentPage}&per_page=${per_page}`,
        "GET",
        {},
        {}
      );
      // console.log("request", request);
      if (request.status === true) {
        setBusiness(request?.today_members);
        setToken(request?.other_details);
        setTransaction(request?.transaction);
        setUserId(request?.user_data.user_id);
        setWalletAddress(request?.user_data.wallet_address);
        setSponserId(request?.user_data.sponser_id);
        setPartners(request?.board_members.founders);
        setFounder(request?.board_members.eps_partners);
        setThailand(request?.thailand_achievers);
        setMonsoon(request?.monsoon_dhamaka_winners);
        setLeadershipwinners(request?.leadership_winners);
        setTokenPrice(request?.user_data.token_price);
        setReferral(request?.referral_link);
        setCapping(request?.capping_status_3x);
        setTodayincome(request?.today_income);
        setTodaybusiness(request?.today_business);
        setSarclubcount(request?.star_club_count);
        setLeadershiprewards(request.leadership_rewards);
        rbcAddress(request?.user_data?.rbc_address);
        setIncomelimitpercent(request?.income_limit_percent);
        // setIncomelimitpercent(99)
        setTotalPages(
          parseInt(request.pagination_record.pagination.total_pages, 10)
        );

        setLoading(false);
      } else {
        toast.error(request.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log("starclubcount", starclubcount);

  // console.log("oartk", partners);
  // console.log("fcgvhbjnk", founder);

  // const handleCopyWallet = () => {
  //     navigator.clipboard.writeText(walletAddress);
  //     setCopySuccess(true);
  //     toast.dismiss();
  //     toast.success("Copied successfully")
  //     setTimeout(() => setCopySuccess(false), 2000);
  // };
  const handleCopyWallet = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText("0x6A50A1f2fF0C5658815215b498f7aB003a783Dc7")
        .then(() => {
          setCopySuccess(true);
          toast.dismiss();
          toast.success("Copied successfully");
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Failed to copy");
          console.error("Could not copy text: ", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = "0x6A50A1f2fF0C5658815215b498f7aB003a783Dc7";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.dismiss();
        toast.success("Copied successfully ");
      } catch (err) {
        toast.dismiss();
        toast.error("Fallback copy failed");
      }
      document.body.removeChild(textArea);
    }
  };

  // const handleCopyReferral = () => {
  //     navigator.clipboard.writeText(referral);
  //     setCopySuccess(true);
  //     toast.dismiss();
  //     toast.success("Copied successfully")
  //     setTimeout(() => setCopySuccess(false), 2000);
  // };
  const handleCopyReferral = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(referral)
        .then(() => {
          setCopySuccess(true);
          toast.dismiss();
          toast.success("Copied successfully");
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Failed to copy");
          console.error("Could not copy text: ", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = referral;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        toast.dismiss();
        toast.success("Copied successfully ");
      } catch (err) {
        toast.dismiss();
        toast.error("Fallback copy failed");
      }
      document.body.removeChild(textArea);
    }
  };

  const handleFounder = () => {
    navigate("/founder_report");
  };
  const handleEps = () => {
    navigate("/eps_report");
  };

  // console.log('business', business);

  const toggleActivity = () => {
    setIsActive(!isActive);
  };

  const handleActionClick = async (action, rewardId, buttonValue) => {
    console.log({ action, rewardId, buttonValue });

    const token = localStorage.getItem("token");
    if (action.button_action) {
      try {
        const requestData = {
          reward_id: parseFloat(rewardId),
          status: parseFloat(buttonValue),
        };

        // console.log({ requestData });

        const response1 = await fetch(`${base_url}api/claim_company_rewards`, {
          method: "POST",

          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        let data1 = await response1.json();
        if (data1.status === true) {
          toast.dismiss();
          toast.success(data1.message);
          // setTimeout(() => {
          //     window.location.reload();
          // }, 2000);
          // fetchData()
          fetchReward();
          // window.location.reload();
        } else {
          toast.error(data1.message);
        }

        if (!response1.ok) {
          throw new Error(`API error: ${response1.status}`);
        }

        // Check if there is content before parsing
        const responseText = await response1.text();
        console.log("Response Text:", responseText);

        if (responseText) {
          const data = JSON.parse(responseText);
          console.log("Parsed Data:", data);

          if (buttonValue === 1) {
            setStatus({ rewardId, value: 1 }); // Claimed
          } else if (buttonValue === 2) {
            setStatus({ rewardId, value: 2 }); // Skipped
          }
        } else {
          console.error("Empty response from API");
        }
      } catch (error) {
        console.error("Error during action click:", error);
      }
    } else {
      setStatus({ rewardId, value: "Waiting" }); // Waiting state
    }
  };

  const getButtonClass = (button) => {
    if (button.button_text === "skipped") {
      return "btn btn-warning text-black text-center"; // Class for skipped button
    }
    if (button.button_text === "Waiting") {
      return "text-center w_btn01"; // Class for waiting state
    }
    if (button.button_text === "claimed") {
      return "btn btn_g"; // Class for skipped button
    }

    if (button.button_value === 1) {
      return "btn btn_g text-center  "; // Class for claimed button
    }
    if (button.button_value === 2) {
      return "btn btn-info  text-center  btn btn-warning "; // Class for skipped button
    }
    return "btn btn-primary "; // Default button class
  };
  const formattedBalance = todaybusiness?.today_team_business?.teamBusiness
    ? parseFloat(todaybusiness?.today_team_business?.teamBusiness).toFixed(2)
    : "0.00";
  const formattedBalances = todayincome?.today_team_income
    ? parseFloat(todayincome?.today_team_income).toFixed(2)
    : "0.00";

  const handleMouseOver = () => {
    marqueeRef.current.stop(); // Pause the marquee
  };

  const handleMouseOut = () => {
    marqueeRef.current.start(); // Resume the marquee
  };
  const handleMouseOvers = () => {
    marqueeRefs.current.stop(); // Pause the marquee
  };

  const handleMouseOuts = () => {
    marqueeRefs.current.start(); // Resume the marquee
  };
  const handleMouseOveres = () => {
    marqueeRefes.current.stop(); // Pause the marquee
  };

  const handleMouseOutes = () => {
    marqueeRefes.current.start(); // Resume the marquee
  };

  const handleSwap = async (e) => {
    const token = localStorage.getItem("token");
    e.preventDefault();
    const amount = isFlipped ? usdt : cvt;
    const type = isFlipped ? "usdt" : "cvt";

    try {
      const response = await fetch(`${base_url}api/swap_token`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type, amount }),
      });
      const data = await response.json();
      if (data.status === true) {
        toast.dismiss();
        toast.success(data.message);
        setCvt("");
        setUsdt("");
      } else {
        toast.dismiss();
        toast.error(data.message || "An error occurred during the swap.");
        setCvt("");
        setUsdt("");
      }
    } catch (error) {
      console.error("Error in swap:", error);
      toast.dismiss();
      toast.error("Something went wrong. Please try again.");
      setCvt("");
      setUsdt("");
    }
  };
  return (
    <>
      <div className="">
        <ModalPop open={showModal} handleClose={handleClose} />
      </div>
      <div class="row">
        <div class="col-xl-9 col-md-8 p-3">
          <div class="custom mt-1">
            <div class="card-custom">
              <div class="d-flex align-items-center">
                <div className="profile-image">
                  <img
                    alt="Profile image of a person"
                    class="profile-img"
                    src={CVTLOGO}
                  />
                </div>
                <div class="profile-info">
                  <div className="proifle-name">
                    <h6 className=" mb-0">{profileName}</h6>
                  </div>
                  <div className="id">
                    <span className="mb-0">ID-{userId}</span>
                  </div>
                  <div className="sponsor-id">
                    <span className="mb-0">Sponsor ID : {sponserId} </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-end">
                {founders.includes("Founder") && (
                  <span className="founder-badge mb-2 mt-1">Founder</span>
                )}
                {founders.includes("EPS_Partner") && (
                  <span className="founder-badge mb-2 mt-1">EPS Partner</span>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between  mb-1">
              <div className="w">
                <span className="w contract_add">Contract Add : </span>
                <span class="mb-0">
                  {/* {rbcaddress} */}
                  0x6A50A1f2fF0C5658815215b498f7aB003a783Dc7
                </span>
              </div>
              <div className="copy-paste_icon">
                <img
                  src={copyed}
                  alt="copy_paste2"
                  className="me-3 mb-1 copyed"
                  onClick={handleCopyWallet}
                />
                <a
                  href="https://bscscan.com/token/0x6a50a1f2ff0c5658815215b498f7ab003a783dc7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="bi bi-link-45deg bi-2xl links"></i>
                </a>
                {/* <i class="bi bi-link-45deg bi-2xl" style={{fontSize:"1.3rem", marginTop:"10px"}} ></i> */}
                {/* <img src={link} className='copy-paste_icons' alt='copy_paste' /> */}
              </div>

              {/* <div className='copy-paste_icons' >
                                {/* <img src={copy} alt='copy_paste' /
                                <img src={link} alt='copy_pastes' />
                            </div> */}
            </div>
          </div>
          <div className="main-box-2">
            <div class="token">
              <div class="card-body_token ">
                <h6 class="card-title">Token Price</h6>
                <div class="card-text ">
                  <span class="display-7">$ {tokenPrice}</span>
                  {/* <span class="badge-token">+15.5%</span> */}
                </div>
                <div className="box-sh"></div>
              </div>
            </div>
            <div class="personal_link-card mt-2">
              <div class="card-body">
                <div className="personal-link">
                  <span>My Personal Link</span>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-link">
                    <p class="card-text">{referral.slice(0, 35)}</p>
                  </div>
                  <div className="copy-paste_icon" onClick={handleCopyReferral}>
                    <img src={copyed} className="copyed" alt="copy_paste2" />
                  </div>
                </div>
                <div class="icon-wrapper d-flex justify-content-start gap-2">
                  <a
                    href="https://www.facebook.com/people/CVToken/61558552580035"
                    target="_blank"
                  >
                    <img className=" fac01" src={fac1} alt="" />
                  </a>
                  <a
                    href="https://www.youtube.com/@CRYPTOVENTURE-hu8cu"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac2}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/_cvtokenofficial"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac3}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://x.com/i/flow/login?redirect_after_login=%2FCryptoV75575"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac4}
                      alt=""
                    />
                  </a>
                  <Link
                    to={
                      `whatsapp://send?text=https://web.cvtoken.us/signup?sponser_id=` +
                      userId
                    }
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac5}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div class="card-capping mt-2">
              <div className="heading-capping">
                <h5 class="text-center">Overall Capping</h5>
              </div>

              {/* <div className="capping-img mt-4">
                                <img className='capping_image' src={cap} alt='capping-pic' />
                            </div> */}
              <div className="capping-img mt-4">
                <div className="ll">
                  <ReactSpeedometer
                    className="capping_image"
                    maxValue={100}
                    value={Math.min(incomelimitpercent, 100)} // Correct way to pass the value
                    needleColor="white"
                    segments={10}
                    customSegmentStops={[
                      0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                    ]}
                    needleWidth={8}
                    ringWidth={40}
                    segmentColors={[
                      "#41d33e",
                      "#41d33e",
                      "#a0ff9e",
                      "#a0ff9e",
                      "#fee114",
                      "#fee114",
                      "#d1d80f",
                      "#ff5656",
                      "#ff5656",
                      "#e33e38",
                    ]}
                    textColor="white"
                    currentValueText={`${Math.min(incomelimitpercent, 100)}%`}
                    needleTransitionDuration={1000}
                    needleTransition="easeElastic"
                    forceRender={true}
                    width={315} // Set width in pixels
                    height={180}
                  />
                </div>
              </div>

              <div className="mt-4">
                {capping?.map((item, index) => (
                  <div key={index} class="d-flex justify-content-between">
                    <div className="profit">
                      <span>{item.title}</span>
                    </div>
                    <div className="profit">
                      <span class="text-end">{item.value}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div class="token-1 mt-2">
              <div className="members mt-2">
                <h4>Board Members</h4>
              </div>
              <div className="d-flex flex-wrap gap-3">
                <div
                  class="board d-flex align-content-flex-start gap-1"
                  onClick={handleFounder}
                >
                  <div className="founder-num">{partners}</div>
                  <div className="founder-nam">Founder's</div>
                </div>
                <div
                  class="board d-flex align-content-flex-start gap-1"
                  onClick={handleEps}
                >
                  <div className="founder-num">{founder}</div>
                  <div className="founder-nam">Partner's</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div className="m-2">
              <div className="heading_name">
                <h3>Dashboard</h3>
              </div>
            </div>
            <div className="row-container">
              {token?.slice(0, 8).map((item, index) => (
                <div key={index} className="card_tos">
                  <div className="d-flex align-items-center p-3">
                    <div className="icons_nill">
                      <img className="icons_total" src={item.icon} alt="" />
                    </div>
                    <div className="content-st">
                      <div className="content-to">
                        <small>{item.title}</small>
                      </div>
                      <div className="content-val">
                        <span>{item.value}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="status">
            <div className="m-3">
              <div className="heading_name">
                <h3>Star Club Status</h3>
              </div>
            </div>
            <div class="status_1 mb-3">
              <div className="text-center position-relative ">
                <img src={starclubcount >= 1 ? oneg : one} alt="" />

                {/* <span className={starclubcount >= 1 ? 'dollar_star ' : 'dollar_star_0'}>
                                    1</span> */}

                <div className="mt-2 curr">
                  <span>$ 2,500</span>
                </div>
              </div>
              <div className="text-center position-relative">
                {/* <img src={vector} alt='' /> */}
                <img src={starclubcount >= 2 ? twog : two} alt="" />

                {/* <span className={starclubcount >= 2 ? 'dollar_star ' : 'dollar_star_0 prav'}>
                                    2</span> */}

                <div className="mt-2 curr">
                  <span>$ 5,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 3 ? threeg : there} alt="" />

                {/* <span className={starclubcount >= 3 ? 'dollar_star_0g ' : 'dollar_star_0'}>
                                    3</span> */}

                <div className="mt-2 curr">
                  <span>$ 10,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 4 ? fourg : four} alt="" />

                {/* <span className={starclubcount >= 4 ? 'dollar_star_0g ' : 'dollar_star_0'}> */}
                {/* 4</span> */}

                <div className="mt-2 curr">
                  <span>$ 25,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 5 ? fiveg : five} alt="" />

                {/* <span className={starclubcount >= 5 ? 'dollar_star_0g ' : 'dollar_star_1 five'}>5</span> */}

                <div className="mt-2 curr">
                  <span>$ 50,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 6 ? sixg : six} alt="" />

                {/* <span className={starclubcount >= 6 ? 'dollar_star_1g ' : 'dollar_star_1'}>6</span> */}

                <div className="mt-2 curr">
                  <span>$ 100,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 7 ? seveng : seven} alt="" />

                {/* <span className='dollar_star_1'>7</span> */}
                {/* <span className={starclubcount >= 7 ? 'dollar_star_1g' : 'dollar_star_1'}>7</span> */}

                <div className="mt-2 curr">
                  <span>$ 500,000</span>
                </div>
              </div>
              <div className="text-center position-relative">
                <img src={starclubcount >= 8 ? eightg : eight} alt="" />

                {/* <span className='dollar_star_2'>8</span> */}
                {/* <span className={starclubcount >= 8 ? 'dollar_star ' : 'dollar_star_2'}>8</span> */}

                <div className="mt-2 curr">
                  <span>$ 1,000,000</span>
                </div>
                <div className="gr"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 p-3">
              <div className="card border_main">
                <div className="card-header p-3">
                  Latest Business Update
                  <hr />
                </div>

                <div className="card-body height-bb p-1 ">
                  <div className="highlight-card ">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="direct-q">
                          <span>Today Members</span>
                        </div>
                        <div className="direct-w">
                          <span>Direct</span>
                        </div>
                        <div className="direct-e">
                          <span>
                            {isActive
                              ? business?.today_direct_active_members
                              : business?.today_direct_inactive_members}
                          </span>
                        </div>
                      </div>
                      <div className="to text-end ">
                        <div className="direct-a">
                          <label className="toggle-switch me-2">
                            <input
                              type="checkbox"
                              checked={isActive}
                              onChange={toggleActivity}
                            />
                            <span className="slider"></span>
                          </label>
                          <span>
                            {isActive ? "Active Users" : "Inactive Users"}
                          </span>
                        </div>
                        <div className="direct-s">
                          <span>Team</span>
                        </div>
                        <div className="direct-d">
                          <span>
                            {isActive
                              ? business?.today_team_active
                              : business?.today_team_inactive}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="highlight-card mt-4">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="direct-q">
                          <span>Today Business</span>
                        </div>
                        <div className="direct-w">
                          <span>Direct</span>
                        </div>
                        <div className="direct-e">
                          <span>
                            {" "}
                            {
                              todaybusiness?.today_direct_business
                                ?.directBusiness
                            }
                          </span>
                        </div>
                      </div>
                      <div className="to text-left">
                        <div className="direct-a">
                          <span>&nbsp;</span>
                        </div>
                        <div className="direct-s">
                          <span>Team</span>
                        </div>
                        {/* <div className="direct-d"><span>${todaybusiness?.today_team_business?.teamBusiness}</span></div> */}
                        <div className="direct-d">
                          <span>{formattedBalance}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="highlight-card mt-4 mb-0">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="direct-q">
                          <span>Today Income</span>
                        </div>
                        <div className="direct-w">
                          <span>Direct</span>
                        </div>
                        <div className="direct-e">
                          <span>{todayincome?.today_direct_income}</span>
                        </div>
                      </div>
                      <div className="text-left">
                        <div className="direct-a">
                          <span>&nbsp;</span>
                        </div>
                        <div className="direct-s">
                          <span>Team</span>
                        </div>
                        <div className="direct-d">
                          <span>{formattedBalances}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="card border_main">
                <div className="card-header p-3">
                  Leadership Achievements
                  <hr />
                </div>
                {leadershiprewards?.map((item, index) => (
                  <div className="card-body p-1 top-class ">
                    <div key={index} className="achievement-item">
                      <div>
                        <div className="hyunda">{item.reward}</div>
                        <div className="dolla">${item.amount} </div>
                        {/* <div className='achieved-badge'> */}
                        <div
                          className={
                            item.status === "Pending"
                              ? "achieved-badge_i"
                              : "achieved-badge"
                          }
                        >
                          <span className="achieve mb-2 mt-1">
                            {item.status}
                          </span>
                        </div>
                      </div>
                      <img src={item.image} alt="Image of BMW X3" />
                    </div>
                    {/* <hr className='' /> */}
                  </div>
                ))}
              </div>
            </div>
            {/* reward */}

            <div className="onew p-3 mt-4">
              <div className="heading_name">
                <h4 className="bep_2">Rewards Hub</h4>
                {/* <h4 className="bep_2">Latest Withdrawals</h4> */}
              </div>

              <div class="card">
                <div class="card-body cash">
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div className="ovbut-1 ms-0">
                        {overallbusiness?.title}
                      </div>
                      <div className="ovbut">
                        Direct Business :{overallbusiness?.direct_business}
                      </div>
                      <div className="ovbut">
                        Team Matching Business :
                        {overallbusiness?.team_matching_business}
                      </div>
                    </div>
                    <div className="">
                      <div className="ovbut-1">{datesbusiness?.title}</div>
                      <div className="ovbut">
                        Direct Business:{datesbusiness?.direct_business}
                      </div>
                      <div className="ovbut">
                        Team Matching Business:
                        {datesbusiness?.team_matching_business}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded">
                <div className="table-responsive mt-3">
                  {loadings ? (
                    <Skeleton
                      count={10}
                      height={50}
                      baseColor="#202020"
                      highlightColor="#444"
                    />
                  ) : (
                    <table className="table dash01 ">
                      <thead className="table-heading">
                        <tr>
                          {/* <th scope="col">Sr No.</th> */}
                          <th scope="col">Timeline</th>
                          <th scope="col">Reward</th>
                          <th scope="col">Direct Business</th>
                          <th scope="col">Team Business</th>
                          <th scope="col">Claim Price</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="tb_colo_k">
                        {hub &&
                          hub.map((item, index) => (
                            <tr key={index}>
                              {/* <td>{index + 1}</td> */}
                              <td>{item.timeline}</td>
                              <td>{item.name}</td>
                              <td>{item.direct_business}</td>
                              <td>{item.team_business}</td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: item.claim_price,
                                }}
                              />
                              <td>
                                {item.buttons.map((button, btnIndex) => (
                                  <button
                                    key={btnIndex}
                                    className={getButtonClass(button)}
                                    onClick={() =>
                                      handleActionClick(
                                        button,
                                        item.reward_id,
                                        button.button_value
                                      )
                                    }
                                  >
                                    {button.button_text}
                                  </button>
                                ))}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-4 p-3">
          <div className="main-box-3">
            <div class="token">
              <div class="card-body_token">
                <h6 class="card-title">Token Price</h6>
                <div class="card-text">
                  <span class="display-7">$ {tokenPrice}</span>
                  {/* <span class="badge-token">+15.5%</span> */}
                </div>
                <div className="box-sh"></div>
              </div>
            </div>
            <div class="personal_link-card mt-2">
              <div class="card-body">
                <div className="personal-link">
                  <span>My Personal Link</span>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-link">
                    <p class="card-text">{referral.slice(0, 35)}</p>
                  </div>
                  <div className="copy-paste_icon" onClick={handleCopyReferral}>
                    <img className="copyed" src={copyed} alt="copy_paste1" />
                  </div>
                </div>
                <div class="icon-wrapper d-flex justify-content-start gap-2">
                  <a
                    href="https://www.facebook.com/people/CVToken/61558552580035"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac1}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@CRYPTOVENTURE-hu8cu"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac2}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/_cvtokenofficial"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac3}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://x.com/i/flow/login?redirect_after_login=%2FCryptoV75575"
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac4}
                      alt=""
                    />
                  </a>
                  <Link
                    to={
                      `whatsapp://send?text=https://web.cvtoken.us/signup?sponser_id=` +
                      userId
                    }
                    target="_blank"
                  >
                    <img
                      className="social-media-icon fac01"
                      src={fac5}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div class="card-capping mt-2">
              <div className="heading-capping">
                <h5 class="text-center">Overall Capping</h5>
              </div>
              {/* <div className="capping-img mt-4">
                                <img className='capping_image' src={cap} alt='capping-pic' />
                            </div> */}
              <div className="capping-img mt-4">
                <div className="ll">
                  <div className="">
                    <ReactSpeedometer
                      className="capping_image"
                      maxValue={100}
                      value={Math.min(incomelimitpercent, 100)} // Correct way to pass the value
                      needleColor="white"
                      segments={10}
                      customSegmentStops={[
                        0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                      ]}
                      needleWidth={8}
                      ringWidth={40}
                      segmentColors={[
                        "#41d33e",
                        "#41d33e",
                        "#a0ff9e",
                        "#a0ff9e",
                        "#fee114",
                        "#fee114",
                        "#d1d80f",
                        "#ff5656",
                        "#ff5656",
                        "#e33e38",
                      ]}
                      textColor="white"
                      currentValueText={`${Math.min(incomelimitpercent, 100)}%`}
                      needleTransitionDuration={1000}
                      needleTransition="easeElastic"
                      forceRender={true}
                      width={200}
                      height={130}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                {capping?.map((item, index) => (
                  <div key={index} class="d-flex justify-content-between">
                    <div className="profit">
                      <span>{item.title}</span>
                    </div>
                    <div className="profit">
                      <span class="text-end">{item.value}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div class="token-1 mt-2">
              <div className="members mt-2">
                <h4>Board Members</h4>
              </div>
              <div className="d-flex flex-wrap gap-3">
                <div
                  class="board d-flex align-content-flex-start gap-1"
                  onClick={handleFounder}
                >
                  <div className="founder-num">{partners}</div>
                  <div className="founder-nam">Founder's</div>
                </div>
                <div
                  class="board d-flex align-content-flex-start gap-1"
                  onClick={handleEps}
                >
                  <div className="founder-num">{founder}</div>
                  <div className="founder-nam">Partner's</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card_achievers mt-2">
              <div class="card-body">
                <h5 class="card-title_p">Thailand Achievers</h5>
                <hr />
                {/* <marquee height="250" direction="up" scrollamount="6">
                                    <ul className="list-group list-group-flush mt-2">
                                        {thailand && thailand.map((item, index) => (
                                            <li className="list-group-item" key={index}>
                                                {item.name} <span className="float-end">{item.user_id}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </marquee> */}
                <marquee
                  height="220"
                  direction="up"
                  scrollamount="6"
                  ref={marqueeRef}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <ul className="list-group list-group-flush mt-2">
                    {thailand &&
                      thailand.map((item, index) => (
                        <li className="list-group-item" key={index}>
                          {item.name}{" "}
                          <span className="float-end">{item.user_id}</span>
                        </li>
                      ))}
                  </ul>
                </marquee>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="card_achievers">
                <div class="card-body">
                  <h5 class="card-title_p">Monsoon Dhamaka</h5>
                  <hr></hr>
                  {/* <marquee height="250" direction="up" scrollamount="6">
                                        <ul className="list-group list-group-flush mt-2">
                                            {monsoon && monsoon.map((item, index) => (
                                                <li className="list-group-item">
                                                    {item.name} <span className="float-end">{item.user_id}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </marquee> */}
                  <marquee
                    height="220"
                    direction="up"
                    scrollamount="6"
                    ref={marqueeRefs}
                    onMouseOver={handleMouseOvers}
                    onMouseOut={handleMouseOuts}
                  >
                    <ul className="list-group list-group-flush mt-2">
                      {monsoon &&
                        monsoon.map((item, index) => (
                          <li className="list-group-item" key={index}>
                            {item.name}{" "}
                            <span className="float-end">{item.user_id}</span>
                          </li>
                        ))}
                    </ul>
                  </marquee>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="card_achievers">
                <div class="card-body">
                  <h5 class="card-title_p">Leadership Winners</h5>
                  <hr></hr>
                  {/* <marquee height="250" direction="up" scrollamount="6">
                                        <ul className="list-group list-group-flush mt-2">
                                            {leadershipwinners && leadershipwinners.map((item, index) => (
                                                <li className="list-group-item">
                                                    {item.name} <span className="float-end">{item.user_id}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </marquee> */}

                  <marquee
                    height="220"
                    direction="up"
                    scrollamount="6"
                    ref={marqueeRefes}
                    onMouseOver={handleMouseOveres}
                    onMouseOut={handleMouseOutes}
                  >
                    <ul className="list-group list-group-flush mt-2">
                      {leadershipwinners &&
                        leadershipwinners.map((item, index) => (
                          <li className="list-group-item" key={index}>
                            {item.name}{" "}
                            <span className="float-end">{item.user_id}</span>
                          </li>
                        ))}
                    </ul>
                  </marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="card_achievers">
                <div className="card-body">
                  <h5 className="card-title_p">Currency Converter</h5>
                  <hr />

                  <form className="text-center" onSubmit={handleSwap}>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-row">
                        <img
                          className="Car1 align-self-center"
                          src={isFlipped ? Car1 : CVTLOGO}
                        />
                        <label className="align-self-center">
                          {isFlipped ? "USDT" : "CVT"}
                        </label>
                      </div>
                      <input
                        className="cur_input converterInput"
                        type="text"
                        value={isFlipped ? usdt : cvt}
                        onChange={
                          isFlipped ? handleUsdtChange : handleCvtChange
                        }
                      />
                    </div>
                    <i
                      className="fa-solid fa-repeat fa-flip my-3 conve"
                      onClick={handleFlip}
                      style={{ cursor: "pointer" }}
                    ></i>
                    <div className="d-flex flex-row">
                      <div className="d-flex flex-row">
                        <img
                          className="Car1 align-self-center"
                          src={isFlipped ? CVTLOGO : Car1}
                        />
                        <label className="me-0 align-self-center">
                          {isFlipped ? "CVT" : "USDT"}
                        </label>
                      </div>
                      <input
                        className="cur_input converterInput"
                        type="text"
                        value={isFlipped ? cvt : usdt}
                        readOnly
                      />
                    </div>

                    <div className="ghy">
                      <button type="submit" className="swap_btn swap_btn1">
                        Swap
                      </button>
                      <NavLink
                        to={"/swaphistory"}
                        type="button"
                        className="btn swap_btn swap_btn2"
                      >
                        History
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* withdraw */}

      <div className="row mb-5">
        <div className="col-12">
          <div className="onew p-2">
            <div className="heading_name">
              <h3>Latest Withdrawals</h3>
              {/* <h3>Rewards Hub</h3> */}
            </div>

            <div className="rounded">
              <div className="table-responsive mt-3">
                {loading ? (
                  <Skeleton
                    count={10}
                    height={50}
                    baseColor="#202020"
                    highlightColor="#444"
                  />
                ) : (
                  <table class="table table-striped table_dash_03">
                    <thead className="table-heading">
                      <tr>
                        <th scope="col">Asset</th>
                        <th scope="col">Transaction Hash</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payable Amount</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody className="tb_colo">
                      {transaction &&
                        transaction.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">
                                {item.asset === "CVT" ? (
                                  <img
                                    className="profile-img0-1"
                                    src={CVTLOGO} 
                                  ></img>
                                ) : (
                                  <img
                                    className="profile-img0-1"
                                    src={Car1}
                                  ></img>
                                )}
                              </td>

                              <td scope="row">
                                <a
                                  className="navlink"
                                  href={`https://bscscan.com/tx/${item.transaction_hash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.transaction_hash?.length > 20
                                    ? `${item.transaction_hash?.slice(
                                        0,
                                        20
                                      )}...`
                                    : item.transaction_hash}
                                </a>
                              </td>
                              <td scope="row">{item.date}</td>
                              <td scope="row">{item.amount}</td>
                              <td scope="row">{item.payable_amount}</td>
                              <td>
                                <button
                                  className="button-latest"
                                  style={{
                                    backgroundColor:
                                      item.status === "APPROVED"
                                        ? "rgba(65, 211, 62, 1)"
                                        : item.status === "REJECTED"
                                        ? "rgba(227, 62, 56, 1)"
                                        : item.status === "PENDING"
                                        ? "rgba(255, 165, 0, 1)" // Orange color for PENDING
                                        : "",
                                    color:
                                      item.status === "APPROVED"
                                        ? "black"
                                        : item.status === "REJECTED"
                                        ? "black"
                                        : item.status === "PENDING"
                                        ? "black"
                                        : "",
                                  }}
                                >
                                  {item.status}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
