import React, { useState } from 'react'
import icon_1 from "../assets/icons/category-2.png"
import icon_2 from "../assets/icons/game.png"
import icon_3 from "../assets/icons/key-square.png"
import group from "../assets/icons/arrow_right_side.png"
import logoPic from '../assets/images/cvt logo web.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import CVTLOGO from '../assets/icons/CVTLOGO.png'
import cvtogoweb from '../assets/images/cvt logo web.png'
import popup from "../assets/icons/popup.png"
import toast from 'react-hot-toast'
const Information = () => {
    const location = useLocation();
    const { userData } = location.state || {}
    const [user, setUser] = useState('');
    // setUser(userData.)
    return (

        <>
            <div className="page">
                <div className="container">
                    <div className="row  ` ">
                        <div className='col-lg-4 col-md-3 col-sm-1'></div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            {/* <div className='login-form'> */}

                            <form className="login-formi">
                                <div className="mb-3 bg mt-5">
                                    <div className='arrow_icon'>

                                        <img src={popup} className='logo_012' style={{width:"60px",marginBottom:"15px"}} alt='arrow-icon' />
                                        <br />



                                        {userData && (
                                            <div>
                                                {/* <h2>Welcome, {userData.message}!</h2> */}

                                                <h4 className='suc02'>Success!</h4>

                                                <h5 className='userid mb-0'> Congratulations your account has been successfully Created </h5>
{/* <span className="user_id012" id='hello'>User id ff: {userData.user_id}</span> */}
                                                <span className="user_id012" id='hello'><span> User id: </span>{userData.user_id}</span>
                                                {/* <p className='user_id012'>Password: {userData.password}</p> */}
                                                <p className='user_id012'><span>Password: </span> {userData.password}</p>
                                                {/* <p>Transaction Password: {userData.txn_password}</p> */}

                                                {/* Add more fields as needed */}
                                            </div>
                                         )}
                                        {/* <div className="now mt-4 d-flex justify-content-center">
                             <NavLink to='/login' className='link'> <button type="submit" className="login-button" >Continue</button></NavLink>
                            </div> */}
                                        {/* <button type="submit" className="login-button" > <NavLink to='/login' style={{color:"white"}}>Continue</NavLink></button> */}
                                        <NavLink to='/login' style={{ color: "white" }}>        <button type="submit" className="login-button" >Continue</button></NavLink>
                                    </div>
                                </div>
                            </form>

                            {/* </div> */}


                        </div>
                        <div className='col-lg-4 col-md-3 col-sm-1'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information
